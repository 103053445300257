import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { Observable, of } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

@Component({
  selector: 'mt-select',
  templateUrl: './mat-select.component.html',
  styleUrls: ['./mat-select.component.scss'],
})
export class MatSelectComponent implements OnInit {



  @Input() placeholder: string = '';
  @Input() list: Array<any> = [];
  @Input() selectedValue: any = null;
  @Input() options: any = {appearance: "fill"};

  @Output() inputReceived = new EventEmitter<any>();

  constructor() {
    // this.getFilteredOptions()
  }

  ngOnInit(): void {

  }

  async ngAfterViewInit(){
  }


  ngOnChanges(){
  }

  onWidgetActionSelected(e){
    console.log("onWidgetActionSelected", e);
    this.inputReceived.emit({value: e.value})
  }


}
