<!-- <div fxLayout fxLayout="center center">
  <spinner *ngIf="!isReceivedBoxObjects && spinner" [center]="true"></spinner>
</div> *ngIf="isReceivedBoxObjects" -->
<form  class="box_object_select_ac example-form" fxLayout="row" fxLayout.lt-md="row wrap" fxLayoutAlign="start center">
  <!-- <div fxLayout="column" fxFlex="60" fxLayoutAlign="start start"> -->
    <mat-form-field class="example-full-width" style="width: 80%;">
      <mat-label>Choose the {{objectDisplayName | titlecase}}</mat-label>
      <mat-icon color="primary" matPrefix>category</mat-icon>
      <input
        #boxObjectInput
        type="text"
        placeholder="Pick one"
        aria-label="box object"
        matInput
        [formControl]="selectBoxObjectControl"
        [matAutocomplete]="auto2"
      >
      <!-- [disabled]="disableBoxObjectInput" -->
      <button matTooltip="Clear selected {{objectDisplayName | titlecase}}" *ngIf="boxObjectInput.value" matSuffix mat-icon-button aria-label="Clear" (click)="selectBoxObjectControl.patchValue('')">
        <mat-icon color="primary">close</mat-icon>
      </button>
      <mat-autocomplete autoActiveFirstOption #auto2="matAutocomplete" [displayWith]="displayFnBox" (optionSelected)="boxObjectSelected($event)">
        <mat-option *ngFor="let boxObject of filteredBoxObjects | async" [value]="boxObject">
          {{ boxObject.name }}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
    <div *ngIf="isCustomObjectSupported">
      <button mat-icon-button color="primary" (click)="createObjectPopup()" matTooltip="Create new {{ objectDisplayName }}">
          <mat-icon>add_circle_outline</mat-icon>
      </button>
    </div>
    <!-- <span *ngIf="oldValue">
      <span style="color: #333; font-size: .9rem;">Existing selection : </span>
      <span style="margin-left: 0 1rem; font-size: 1rem;"><b>{{ oldValue }}</b></span>
    </span> -->
  <!-- </div> -->

  <button
    mat-icon-button color="primary"
    matTooltip="Refresh"
    (click)="refreshBoxObjects()"
  >
    <mat-icon>refresh</mat-icon>
  </button>
  <mat-spinner
        *ngIf="spinner"
        color="warn"
        [diameter]="20"
      ></mat-spinner>
</form>
