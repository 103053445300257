<spinner
  [center]="true"
  size="la-2x"
  color="grey"
  type="ball-triangle-path"
></spinner>

<div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
  <h1 mat-dialog-title>Create {{ data.sourceObjName }} {{displayNameObj.app.objDisplayName | titlecase}} in {{ data.appObjName }} </h1>
  <mat-form-field>
    <mat-label>{{displayNameObj.app.objDisplayName | titlecase}} Name</mat-label>
    <input matInput (input)="onChangeOfObjectName($event.target.value)" [(ngModel)]="objectName" />
  </mat-form-field>
</div>

<div mat-dialog-content>
  <section class="dialog-container mat-elevation-z8" tabindex="0"> 
    <table mat-table [dataSource]="dataSource">

      <!-- Checkbox Column -->
      <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef>
          <mat-checkbox color="primary" (change)="$event ? toggleAllRows() : null"
            [checked]="selection.hasValue() && isAllSelected()"
            [indeterminate]="selection.hasValue() && !isAllSelected()"
            [aria-label]="checkboxLabel()">
          </mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let row">
          <mat-checkbox color="primary" (click)="$event.stopPropagation()"
            (change)="$event ? selection.toggle(row) : null"
            [checked]="selection.isSelected(row)"
            [aria-label]="checkboxLabel(row)">
          </mat-checkbox>
        </td>
      </ng-container>
    
      <!-- Type Column -->
      <ng-container matColumnDef="type">
        <th mat-header-cell *matHeaderCellDef> Type </th>
        <td mat-cell *matCellDef="let element"> {{element.type}} </td>
      </ng-container>
    
      <!-- Zoho Attribute Name Column -->
      <ng-container matColumnDef="zoho_attr_name">
        <th mat-header-cell *matHeaderCellDef >
          <div fxLayout="row" fxLayoutGap="5px">
            <img [src]="data.sourceInfo?.logo?.url" width="20px" height="20px"/>  
            <span>{{ data.sourceObjName }} {{ displayNameObj.source.attrDisplayName | titlecase }} Name</span>
          </div>
        </th>
        <td mat-cell *matCellDef="let element"> {{element.zoho_attr_name}} </td>
      </ng-container>
    
      <!-- App Attribute Name Column -->
      <ng-container matColumnDef="app_attr_name">
        <th mat-header-cell *matHeaderCellDef>
          <div fxLayout="row" fxLayoutGap="5px">
            <img [src]="data.appInfo?.logo?.url" width="20px" height="20px"/>  
            <span>{{data.appObjName }} {{ displayNameObj.app.attrDisplayName | titlecase }} Name</span> 
          </div>
        </th>
        <td mat-cell *matCellDef="let element"> {{element.app_attr_name}} </td>
      </ng-container>
    
      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"
          (click)="selection.toggle(row)">
      </tr>
    </table>
  </section>
</div>
<div mat-dialog-actions fxLayout="row" fxLayoutAlign="space-between center">
  <div fxLayout="row" fxLayoutGap="30px">
    <div>
      <button mat-raised-button *ngIf="!canContinueEnabled" color="primary" cdkFocusInitial (click)="createObject()">Create {{displayNameObj.app.objDisplayName | titlecase}} Now</button>
      <button mat-stroked-button *ngIf="canContinueEnabled" color="primary" cdkFocusInitial (click)="createObject()">Create {{displayNameObj.app.objDisplayName | titlecase}} Now</button>
      <button mat-button mat-dialog-close>Cancel</button>
    </div>
    <div fxLayout="row" *ngIf="statusMessage" fxLayoutAlign="center center" [ngClass]="isError ? 'warning' : 'success'">
      <mat-icon>{{ isError ? 'close' : 'done' }}</mat-icon>
      <b>{{ statusMessage }}</b>
    </div>
  </div>
  <div *ngIf="canContinueEnabled">
    <button mat-raised-button [mat-dialog-close]="objResponse" color="primary">Continue</button>
  </div>
</div>