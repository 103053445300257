import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ConnectionService } from 'src/app/modules/organization/connection.service';
import { MatDialog } from '@angular/material/dialog';
import { SpinnerService } from 'src/app/shared/spinner/spinner.service';
import { BoxService } from '../../services/box.service';
import { WidgetRegistry } from 'src/app/bloom/models/WidgetRegistry';

@Component({
  selector: 'query-param-inputs',
  templateUrl: './query-param-inputs.component.html',
  styleUrls: ['./query-param-inputs.component.scss']
})
export class QueryParamInputComponent implements OnInit {


  queryBody:any = {};
  constructor(
    private spin: SpinnerService
  ) { }

  @Input() isDynamicData: boolean = false;
  @Input() queryData: any = [];
  @Input() existingInputs:any;
  @Output() "inputParameter" = new EventEmitter<any>();
  @Input() sourceFields: any;
  @Input() systemFields: any;

  fieldMap: any;

  async ngOnInit() {
    if(this.isDynamicData && this.existingInputs?.length) this.queryData = this.queryData.concat(this.existingInputs);
    this.constructFieldMap();
  }

  addNewQueryParameter(): void {
    this.queryData.push({
      dataType: "string",
      name: "",
      __id: "",
      value: ""
    });
  }

  deleteQueryParameter(index: number): void {
    this.queryData.splice(index, 1);
  }

  inputAdded(e){
    this.inputParameter.emit(this.queryData);
  }

  insertCursor(e, data){
    let value = e.__id
    let cursorValue = '${' + value + '}';
    data.value = cursorValue;
    console.log("datavalue", data)
  }

  constructFieldMap(){
    let fieldObj:any = {};
    let list = [];

    if(this.sourceFields?.length > 0) {
      list.push('sourceFields');
      let fieldObjsourceFields = this.getFieldMapObject('sourceFields', 'SOURCE FIELDS', this.sourceFields)
      fieldObj[fieldObjsourceFields.id] = fieldObjsourceFields
    }
    if(this.systemFields?.length > 0) {
      list.push('systemFields');
      var fieldObjsystemFields = this.getFieldMapObject('systemFields','SYSTEM FIELDS',this.systemFields)
      fieldObj[fieldObjsystemFields.id] = fieldObjsystemFields
    }

    fieldObj.list = list;
    this.fieldMap = fieldObj;
    console.log("FIELD MAP PREPARED : ",this.fieldMap)
  }

  getFieldMapObject(_id:string, displayName:string, fields:any, option?:any){
    var obj = {
      id : _id,
      displayName : displayName,
      fields : fields,
      options : option ? option : {}
    }
    return obj
  }

}
