import { Component, OnInit, Input, Output, ViewChild, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatMenuTrigger } from '@angular/material/menu';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BaseWidgetComponent } from '../base-widget/base-widget.component';
import { WidgetUtilityService } from 'src/app/bloom/services/widget-utility.service';
import { PageService } from 'src/app/bloom/services/page-service.service';
import { Subject } from 'rxjs';
import { MetaService } from 'src/app/bloom/services/meta-service';
import { ResourcePermissionService } from 'src/app/shared/services/resource-permission.service';

@Component({
  selector: 'app-chips',
  templateUrl: './chips.component.html',
  styleUrls: ['./chips.component.css']
})
export class ChipsComponent extends BaseWidgetComponent implements OnInit, OnChanges {

  contextMenuActions: any;
  editingHeading: boolean = false;
  hoveredNow: boolean = false;
  styles: any;
  availableItems: any[] = []
  readonly separatorKeysCodes = [ENTER, COMMA] as const;
  private destroy:any = new Subject();

  @ViewChild('menuTrigger') chipsMenuTrigger: MatMenuTrigger
  oldStaticOption: any;

  constructor(
    private widgetUtilityService: WidgetUtilityService,
    private pageService: PageService,
    public metaService: MetaService,
    public resourcePermissionService: ResourcePermissionService
  ) {
    super(metaService, pageService, resourcePermissionService)
  }

  ngOnInit(): void {
    super.ngOnInit()
    this.destroy = this.metaService.$contextChanged.subscribe((contextActions: any) => {
      if(contextActions && this.widgetMeta.id == contextActions?.widgetId){
        this.action(contextActions)
      }
    })
  }

  ngOnDestroy(): void {
    this.destroy.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.selectedWidgetId && (changes.selectedWidgetId.currentValue !== this.widgetMeta.id)) {
      if (this.chipsMenuTrigger && this.chipsMenuTrigger.menuOpen) {
        this.chipsMenuTrigger.closeMenu()
      }
    }
    if(changes.contextActions?.currentValue){
      this.action(changes.contextActions.currentValue)
    }
    if(changes.widgetMeta?.currentValue){
      this.generateAvailableOptions(false)
      this.setContextActions()
    }
  }

  ngDoCheck(){
    let oldOpts = JSON.stringify(this.oldStaticOption);
    let newOpts = JSON.stringify(this.widgetMeta.config.availableOptions.staticOptions)
    if(oldOpts != newOpts){
      this.oldStaticOption = this.widgetMeta.config.availableOptions.staticOptions;
      this.generateAvailableOptions(false)
    }
  }

  setContextActions(){
    this.contextMenuActions = {
      actions: [
        "edit",
      ]
    }
    if(this.widgetMeta.textFormat){
      this.contextMenuActions.actions.unshift(...[
        "bold",
        "underline",
        "italic",
        "color",
        "fontSize",
        "fontFamily",
      ])
    }
    this.raiseContextMenuActions.emit(this.contextMenuActions)
  }

  action(event) {
    console.log("action is", event)
    switch (event.actionType) {
      case "delete":
        this.onDelete();
        break;
      case "updateStyles":
        if (event?.data) {
          this.widgetMeta = event.data;
          console.log("localMeta changed", this.widgetMeta)
          this.newWidgetMeta.emit(this.widgetMeta)
          // this.pageService.updateWidgetInPage(this.widgetMeta, this.panelId)
        }
        // this.newWidgetMeta.emit(this.widgetMeta)
        super.generateStyles();
        break;

      case "customPropertyUpdate":
        this.widgetMeta.config[event.propertyName].value = event.data
        console.log("custom property changed", event.propertyName, this.widgetMeta)
        // this.newWidgetMeta.emit(this.widgetMeta)
        this.pageService.updateWidgetInPage(this.widgetMeta, this.panelId)
        break;
      case 'settingsChanged':
        this.widgetMeta = event.data
        this.generateAvailableOptions()
        // this.newWidgetMeta.next(this.widgetMeta)
        this.pageService.updateWidgetInPage(this.widgetMeta, this.panelId)
        break;
      default:
        break;
    }
  }

  add(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();

    // Add our chip
    if (value) {
      // this.widgetMeta.chips.push({ focussed: false, text: value });
      this.widgetMeta.config.availableOptions.staticOptions.push({
        name: value,
        value: value
      })
    }
    this.generateAvailableOptions(true)
    console.log("new meta", this.widgetMeta)
    // this.newWidgetMeta.emit(this.widgetMeta)
    this.pageService.updateWidgetInPage(this.widgetMeta, this.panelId)

    // Clear the input value
    event.chipInput!.clear();
  }


  remove(chip: any, index): void {
    // const index = this.widgetMeta.findIndex.indexOf(chip);

    if (index >= 0) {
      this.widgetMeta.config.availableOptions.staticOptions.splice(index, 1);
    }
    this.generateAvailableOptions(true)
    console.log("new meta", this.widgetMeta)
    // this.newWidgetMeta.emit(this.widgetMeta)
    this.pageService.updateWidgetInPage(this.widgetMeta, this.panelId)
  }


  onClick(event: any) {
    if(!this.builderMode) return
    this.selectedWidgetId = this.widgetMeta.id
    console.log("icon clicked", this.widgetMeta.id)
    this.widgetSelection.emit(this.widgetMeta.id)

  }

  titleViewToggle(flag: boolean){
    if (this.widgetMeta.config.title.hasOwnProperty('show')) {
      this.widgetMeta.config.title.show = flag
    }
    this.pageService.updateWidgetInPage(this.widgetMeta, this.panelId)
  }

  onDelete() {
    if(!this.builderMode) return
    console.log("widget ID", this.widgetMeta.id, "will be deleted")
    this.widgetDeletion.emit(this.widgetMeta.id)
    this.chipsMenuTrigger.closeMenu();
  }

  saveNewHeading(newHeaderRef: any) {
    console.log("new title will be saved", newHeaderRef.value)
    if (newHeaderRef.value == '') {
      console.log("empty header received, cant update")
      return
    }
    this.widgetMeta.config.title.value = newHeaderRef.value

    // this.newWidgetMeta.emit(this.widgetMeta)
    this.pageService.updateWidgetInPage(this.widgetMeta, this.panelId)
  }

  async generateAvailableOptions(noRefetch: boolean = false, widget?: any){
    console.log("generate available options hit", noRefetch)

    this.oldStaticOption = this.widgetMeta.config.availableOptions.staticOptions;

    let staticOptions: any[] = this.widgetMeta.config.availableOptions.staticOptions

    let rawDynamicOptions: any[] = []
    let dynamicOptionItems: any[] = []
    if(noRefetch){
      dynamicOptionItems = this.availableItems.filter(opt => opt.type == 'dynamic')
      console.log("preserved dynamic options", dynamicOptionItems)
    }else{
      // rawDynamicOptions = await this.widgetUtilityService.fetchDynamicOptions(this.widgetMeta, this.builderMode)
      dynamicOptionItems = this.widgetUtilityService.processDynamicOptions(rawDynamicOptions, this.widgetMeta)
    }

    console.log("chip staticOptionsg w", this.widgetMeta.config.availableOptions)
    console.log("chip staticOptionsg", staticOptions)

    if(this.builderMode){
      staticOptions.map(opt => opt['type'] = 'static')
      dynamicOptionItems.map(opt => opt['type'] = 'dynamic')
    }

    this.availableItems = []
    this.availableItems = this.availableItems.concat(staticOptions)
    this.availableItems = this.availableItems.concat(dynamicOptionItems)

    console.log("chip options", this.availableItems)
    this.emitUserInput()
  }

  emitUserInput(){

    let value;

    if(this.widgetMeta.config.csvMode){
      value = ''
      this.availableItems.forEach((val, i) => {
        if(i > 0) value += ','
        value += val.value
      })
    }else{
      value = []
      this.availableItems.forEach(e=>{
        value.push(e.value)
      })
    }
    let userInput: any = {
      dataBindConfig: this.widgetMeta?.dataBindConfig,
      widgetId: this.widgetMeta.id,
      value: value
    }
    // console.log("emitter data:", userInput)
    this.userInputReceived.emit(userInput)
  }

  // onChipClick(chip, i){
  //   console.log("chip clicked", chip, "index", i)
  //   if(this.widgetMeta.config.navigationEnabled.value && this.widgetMeta.config.navigationFieldName.value){
  //     console.log("can navigate: field name", this.widgetMeta.config.navigationFieldName.value)
  //   }
  // }


}
