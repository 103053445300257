<div
  *ngIf="builderMode"
  class="outerCover"
  [style.background-color]="widgetMeta.config.backgroundColor ? widgetMeta.config.backgroundColor.value : 'transparent'"
  fxLayout fxLayoutAlign="center center"
  [class.selectedNow]="selectedWidgetId === widgetMeta.id"
  [class.hoveredNow]="selectedWidgetId === widgetMeta.id? false: hoveredNow"
  (mouseenter)="widgetMouseenter()"
  (mouseleave)="widgetMouseleave()"
  (click)="onClick()"
  [matTooltip]="widgetMeta.config.tooltip && widgetMeta.config.tooltip.enabled ? widgetMeta.config.tooltip.value : ''"
  [matTooltipDisabled]="!widgetMeta.config.tooltip || !widgetMeta.config.tooltip.enabled"
>
  <!-- [matMenuTriggerFor]="menu"
  #menuTrigger="matMenuTrigger" -->
  <widget-drag-handle *ngIf="widgetOptions?.dragDrop !== false" [class.invisible]="(selectedWidgetId === widgetMeta.id || hoveredNow) ? false : true"></widget-drag-handle>
  <form *ngIf="!widgetMeta.config.viewOnly || !widgetMeta.config.viewOnly.value" class="innerCover">
    <inline-title [widgetMeta]="widgetMeta" [builderMode]="builderMode" [styles]="styles" (widgetTitleChanged)="newWidgetMeta.emit(widgetMeta)"></inline-title>
    
    <mat-form-field 
      style="width: 100%"
      [appearance]="this.widgetMeta.config.appearance && this.widgetMeta.config.appearance.value ? this.widgetMeta.config.appearance.value : 'standard'"
    >
      <mat-label class="autocompleteTitle" *ngIf="!widgetMeta.config.title?.show || !widgetMeta.config.title?.value?.length">{{ widgetMeta.config.label.value }}</mat-label>
      <input
        type="text"
        #acInput
        [placeholder]="widgetMeta.config.placeholder.value"
        aria-label="widgetMeta.config.label.value"
        matInput
        [required]="widgetMeta?.required ? widgetMeta.required : false"
        [formControl]="myControl"
        [matAutocomplete]="auto"
      >
      <!-- *ngIf="preparingOptions" -->
      <div fxLayoutAlign="end center" *ngIf="preparingOptions"><spinner [selfSpin]="true" [size]="'la-sm'"></spinner></div>
      <button mat-icon-button matSuffix matTooltip="Retry" *ngIf="dynamicOptionsError" (click)="generateAvailableOptions()">
        <mat-icon>refresh</mat-icon>
      </button>
      <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
        <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
          {{option.name}}
        </mat-option>
      </mat-autocomplete>

      <mat-hint class="error-text" *ngIf="dynamicOptionsError">{{ dynamicOptionsError }}</mat-hint>
    </mat-form-field>
  </form>
  <div class="innerCover" *ngIf="widgetMeta.config.viewOnly && widgetMeta.config.viewOnly.value">
    {{ myControl && myControl.value ? myControl.value.name : "" }}
  </div>
</div>


<div
  *ngIf="!builderMode" class="outerCover" (click)="onClick()" fxLayout fxLayoutAlign="center center"
  [style.background-color]="widgetMeta.config.backgroundColor ? widgetMeta.config.backgroundColor.value : 'transparent'"
  [matTooltip]="widgetMeta.config.tooltip && widgetMeta.config.tooltip.enabled ? widgetMeta.config.tooltip.value : ''"
  [matTooltipDisabled]="!widgetMeta.config.tooltip || !widgetMeta.config.tooltip.enabled"
>
  <form class="innerCover" *ngIf="!widgetMeta.config.viewOnly || !widgetMeta.config.viewOnly.value">
    <inline-title [widgetMeta]="widgetMeta" [builderMode]="builderMode" [styles]="styles"></inline-title>

    <mat-form-field 
      style="width: 100%" 
      [appearance]="this.widgetMeta.config.appearance && this.widgetMeta.config.appearance.value ? this.widgetMeta.config.appearance.value : 'standard'"
    >
      <mat-label class="autocompleteTitle" *ngIf="!widgetMeta.config.title?.show || !widgetMeta.config.title?.value?.length">{{ widgetMeta.config.label.value }}</mat-label>
      <input type="text"
        placeholder="Pick one"
        aria-label="widgetMeta.config.label.value"
        matInput
        [formControl]="myControl"
        [matAutocomplete]="auto"
        [required]="widgetMeta?.required ? widgetMeta.required : false"
      >
      <div fxLayoutAlign="end center" *ngIf="preparingOptions"><spinner [selfSpin]="true" [size]="'la-sm'"></spinner></div>
      <button mat-icon-button matSuffix matTooltip="Retry" *ngIf="dynamicOptionsError" (click)="$event.stopPropagation();generateAvailableOptions()">
        <mat-icon>refresh</mat-icon>
      </button>
      <mat-hint *ngIf="widgetMeta.note">
        <span style="font-size: small;margin-left: 10px; color: gray;" *ngIf="widgetMeta.note?.message">Note: {{widgetMeta.note.message}}</span>
        <a *ngIf="widgetMeta.note?.knowMoreLink" style="font-size: small;" [href]="widgetMeta.note.knowMoreLink" target="_blank"> &nbsp;know more</a>
      </mat-hint>
      <mat-hint class="error-text" *ngIf="dynamicOptionsError">{{ dynamicOptionsError }}</mat-hint>

      <button matTooltip="Clear selection" *ngIf="myControl.value" matSuffix mat-icon-button aria-label="Clear"
        (click)="clearSelection($event)" >
        <mat-icon>close</mat-icon>
      </button>
      <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn" (optionSelected)="selectionHandler($event)">
        <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
          {{option.name}}
        </mat-option>
      </mat-autocomplete>
      <mat-error *ngIf="fc.invalid && fc.hasError('required')">This field is required</mat-error>
    </mat-form-field>
  </form>
  <div *ngIf="widgetMeta.config.viewOnly && widgetMeta.config.viewOnly.value" class="innerCover">
    {{ myControl && myControl.value ? myControl.value.name : "" }}
  </div>
</div>
