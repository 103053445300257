import { Injectable, Injector } from '@angular/core';
import { WidgetManager } from '../models/WidgetManager';
import { WidgetService } from './widget-service.service';
import { WidgetAction } from '../models/Action/WidgetAction';
import { WidgetUtilityService } from './widget-utility.service';
import { TemplateEngine } from 'src/app/core/common/TemplateEngine';

@Injectable({
  providedIn: 'root'
})
export class AttributeService {
  constructor(
    private widgetService: WidgetService,
  ) { }

  manageSourceFields(pageMeta, widget?){
    let te = new TemplateEngine();
    let result = {
      sourceFields: [],
      whitelabeledFields: []
    }
    pageMeta?.panels?.forEach(panelMeta => {
      let panel = panelMeta;
      let widgets = this.widgetService.getWidgetsFromPanel(panel);
      widgets.forEach((widget ) => {

        if(WidgetManager.getWidget(widget.type)?.getMappingConfig(widget)){
          let config = WidgetManager.getWidget(widget.type)?.getMappingConfig();
          let data = {
            panel: panel,
            widget: widget
          }
          result.sourceFields.push(te.fillTemplateObject(config, data))
        }

        if(widget.type == "connection" && WidgetManager.getWidget(widget.type)?.getMappingConfig(widget)){
          let config = WidgetManager.getWidget(widget.type)?.getMappingConfig();
          let data = {
            panel: panel,
            widget: widget
          }
          result.whitelabeledFields.push(te.fillTemplateObject(config, data));
        }
      })
    })

    return result;
  }

}
