<div class="full-width" [ngSwitch]="type" *ngIf="!isImageClose">
  <div class="tab-container" fxLayoutAlign="center center" *ngSwitchCase="'dropzone'">
    <div class="drop-zone-container" [ngStyle]="{width: width,height:height}" fxLayout="column"
      fxLayoutAlign="center center" appFilePicker [disabled]="disabled" #dropZonePicker="appFilePicker"
      [multiple]="_multiple" (filesChanged)="_onFilesChanged($event)" (filesReset)="_onReset()" [accept]="accept"
      (mouseenter)="addOutline()" (mouseleave)="removeOutline()">
      <mat-icon color="primary">add_circle</mat-icon>
      <span>Drag & Drop Image</span>
    </div>
  </div>


  <div class="tab-container" fxLayoutAlign="center center" *ngSwitchCase="'button'">
    <button #buttonPicker="appFilePicker" mat-raised-button color="primary" appFilePicker [disabled]="disabled"
      [multiple]="_multiple" (filesChanged)="_onFilesChanged($event)" (filesReset)="_onReset()" [accept]="accept">
      <div fxLayout="row" fxLayoutGap="5px" fxLayoutAlign="center center">
        <mat-icon>image</mat-icon><span>{{displayText || 'Choose Image'}}</span>
      </div>
    </button>
  </div>
  <div class="tab-container" fxLayoutAlign="center center" *ngSwitchCase="'change_button'">
    <button #buttonPicker="appFilePicker" mat-raised-button color="primary" appFilePicker [disabled]="disabled"
      [multiple]="_multiple" (filesChanged)="_onFilesChanged($event, false)" (filesReset)="_onReset()" [accept]="accept">
      <div fxLayout="row" fxLayoutGap="5px" fxLayoutAlign="center center">
        <mat-icon>image</mat-icon><span>Change Image</span>
      </div>
    </button>
  </div>
  <button *ngSwitchCase="'iconbutton'"  matTooltip="Upload image" #buttonPicker="appFilePicker" mat-icon-button color="primary" appFilePicker
    [disabled]="disabled" [multiple]="_multiple" (filesChanged)="_onFilesChanged($event,false)"
    (filesReset)="_onReset()" [accept]="accept">
    <mat-icon matTooltip="Upload image">file_upload</mat-icon>
  </button>

  <div class="full-width" *ngSwitchCase="'both'" fxLayout="column" fxLayoutAlign="center stretch" fxLayoutGap="10px">
    <div fxLayoutAlign="center center">
      <div class="drop-zone-container" [ngStyle]="{width: width, height:height}" fxLayout="column"
        fxLayoutAlign="center center" appFilePicker #dropZonePicker="appFilePicker" [multiple]="_multiple"
        [disabled]="disabled" (filesChanged)="_onFilesChanged($event)" (filesReset)="_onReset()" [accept]="accept">
        <mat-icon color="primary">add_circle</mat-icon>
        <span>Drag & Drop Image</span>
      </div>
    </div>
    <div fxLayoutAlign="center center">
      <button #buttonPicker="appFilePicker" mat-raised-button color="primary" appFilePicker [multiple]="_multiple"
        [disabled]="disabled" (filesChanged)="_onFilesChanged($event)" (filesReset)="_onReset()" [accept]="accept">
        <div fxLayout="row" fxLayoutGap="5px" fxLayoutAlign="center center">
          <mat-icon>image</mat-icon><span>{{displayText || 'Choose Image'}}</span>
        </div>
      </button>
    </div>
  </div>
</div>

<div class="dialog" [ngStyle]="{width: '500px',height: 'auto'}" *ngIf="isImageClose && !miniPreview">
  <ng-container *ngFor="let src of imageSrc; let i = index" [fxLayoutAlign]="imageSrc?.length > 1 ? 'start center' : 'center center'">
    <div class="actionButtonWrap" fxLayoutAlign="flex-end" fxLayoutGap="1rem">
      <button  
        mat-icon-button #buttonPicker="appFilePicker" 
        class="close-thin icon-button-small" appFilePicker [multiple]="_multiple"
        [disabled]="disabled" (filesChanged)="_onFilesChanged($event)" (filesReset)="_onReset()" [accept]="accept"
      >
        <mat-icon>edit</mat-icon>
      </button>
      <button mat-icon-button class="close-thin icon-button-small" (click)="this.isImageClose=false;fileRemoved(i)">
        <mat-icon>close</mat-icon>
      </button>
    </div>
    <img [src]="src" *ngIf="src?.length > 0" class="image-layout" />
  </ng-container>
  
  <!-- <button *ngIf="!imageSrc.length || multiple" #buttonPicker="appFilePicker" mat-raised-button color="primary" appFilePicker [disabled]="disabled"
    (filesChanged)="_onFilesChanged($event, true, imageSrc.length)" [multiple]="_multiple" (filesReset)="_onReset()" [accept]="accept">
    <div fxLayout="row" fxLayoutGap="5px" fxLayoutAlign="center center">
      <mat-icon>image</mat-icon>
      <span *ngIf="!multiple">{{ imageSrc.length || displayImage?.length ? "Replace Image" : (displayText || "Choose Image") }}</span>
      <span *ngIf="multiple">{{ imageSrc.length || displayImage?.length ? "Add more" : (displayText || "Choose Image") }}</span>
    </div>
  </button> -->
</div>

<div *ngIf="miniPreview && ((isImageClose && isIterable) || displayImage?.length > 0)" style="text-align: center;">
  <div fxLayout="row wrap"
  [fxLayoutAlign]="displayImage?.length > 1 || imageSrc?.length > 1 ? 'start center' : 'center center'" style="width: 100%;">
    <ng-container *ngIf="imageSrc.length">
      <ng-container *ngFor="let src of imageSrc; let i = index"
      [fxLayoutAlign]="imageSrc?.length > 1 ? 'start center' : 'center center'"
      >
        <div *ngIf="src" class="miniPreviewOuterWrap" fxFlex="15"
        (mouseenter)="imageMouseenter(i)"
        (mouseleave)="imageMouseleave(i)">
          <div class="miniPreviewWrap" fxLayout="column" fxLayoutAlign="center center">

            <div  fxLayoutAlign="end end" fxLayout="row" *ngIf="imageOption?.[i]?.mousein">
              <!-- <button mat-icon-button #buttonPicker="appFilePicker" class="close-thin icon-button-small" appFilePicker [multiple]="false"
                [disabled]="disabled" (filesChanged)="_onFilesChanged($event, true, i)" (filesReset)="_onReset()" [accept]="accept">
                <mat-icon>edit</mat-icon>
              </button> -->

                <mat-icon [accept]="accept" #buttonPicker="appFilePicker" class="close-thin icon-button-small" appFilePicker [multiple]="false"
                [disabled]="disabled" (filesChanged)="_onFilesChanged($event, true, i)" (filesReset)="_onReset()">edit</mat-icon>

                <!-- <button mat-icon-button class="close-thin icon-button-small" (click)="fileRemoved(i)">
                <mat-icon>close</mat-icon> -->
                <mat-icon class="close-thin icon-button-small" (click)="fileRemoved(i)">close</mat-icon>
            </div>

            <div>
              <img [src]="src" *ngIf="src" style="width: 100%; height: auto;" class="image-layout" />
            </div>
          </div>
        </div>
      </ng-container>
    </ng-container>

    <ng-container *ngIf="displayImage?.length"
    [fxLayoutAlign]="displayImage?.length > 1 ? 'start center' : 'center center'" >
      <ng-container *ngFor="let src of displayImage; let i = index"
      >
        <div *ngIf="src.url" class="miniPreviewOuterWrap" fxFlex="15"
        (mouseenter)="displayImageMouseenter(i)"
        (mouseleave)="displayImageMouseleave(i)">
          <div class="miniPreviewWrap">
            <div fxLayoutAlign="end end" fxLayout="row">
              <div
              *ngIf="displayImageOption?.[i]?.mousein">
                <mat-icon class="close-thin icon-button-small" (click)="displayFileRemoved(displayImage, i)">close</mat-icon>
              </div>
            </div>
            <div >
              <img [src]="src.url" *ngIf="src.url" style="width: 100%; height: auto;" class="image-layout" />
            </div>
          </div>
        </div>
      </ng-container>
    </ng-container>
  </div>
  <button *ngIf="!imageSrc.length || multiple" #buttonPicker="appFilePicker" mat-raised-button color="primary" appFilePicker [disabled]="disabled"
    (filesChanged)="_onFilesChanged($event, true, imageSrc.length)" (filesReset)="_onReset()" [accept]="accept"> <!--[multiple]="_multiple"-->
    <div fxLayout="row" fxLayoutGap="5px" fxLayoutAlign="center center">
      <mat-icon>image</mat-icon>
      <span *ngIf="!multiple">{{ imageSrc.length || displayImage?.length ? "Replace Image" : (displayText || "Choose Image") }}</span>
      <span *ngIf="multiple">{{ imageSrc.length || displayImage?.length ? "Add more" : (displayText || "Choose Image") }}</span>
    </div>
  </button>
</div>

<!--<div>
  <mat-toolbar>
    <span>Selected Files:</span>
  </mat-toolbar>
  <app-selected-files-table [files]="_selectedFiles"></app-selected-files-table>
</div>-->
