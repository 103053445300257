import { Component, OnInit, Input, Output, EventEmitter, ViewChild, AfterViewInit, SimpleChanges, OnChanges, DoCheck, ViewEncapsulation, ChangeDetectorRef, ElementRef, Renderer2 } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { Subject } from 'rxjs';
import { MetaService } from 'src/app/bloom/services/meta-service';
import { PageService } from 'src/app/bloom/services/page-service.service';
import { BaseWidgetComponent } from '../base-widget/base-widget.component';
import { ActionManager } from 'src/app/bloom/models/Action/ActionManager';
import { environment } from 'src/environments/environment';
import { WidgetUtilityService } from 'src/app/bloom/services/widget-utility.service';
import { ResourcePermissionService } from 'src/app/shared/services/resource-permission.service';

interface Dimensions {
  // height: string,
  // width: string,
  fixedHeight?: boolean,
  fixedWidth?: boolean,
  aspectRatio?: string
}

@Component({
  selector: 'app-image',
  templateUrl: './image.component.html',
  styleUrls: ['./image.component.css'],
  encapsulation: ViewEncapsulation.Emulated
})
export class ImageComponent extends BaseWidgetComponent implements OnInit, OnChanges, DoCheck {

  @Output() applyImageHeight = new EventEmitter<any>();
  @Output() onExecuteAction = new EventEmitter<any>();

  @Input () dimensions: Dimensions
  @Input () cardImage: boolean
  contextMenuActions: any = {};

  alignmentString: string = 'center center'
  initialRenederedHeight: number;
  initialRenederedWidth: number;
  initialGridY: number;
  twoDResizedMetaSubscription: any;
  effectiveHeight: any
  localStyles: any = {  'max-width': '100%', 'max-height': '100%' } // 'aspect-ratio': '16/9', 'max-height': '100%',
  isMulti: boolean = false
  imgSrc: string = ''
  srcArr: string[] = ['']

  oldMeta: any
  isLoading: boolean = false

  @ViewChild('img') image
  @ViewChild('cover') cover

  no_image_placeholder_url: string = environment.ASSETS_BASE_URL + environment.IMAGE_PLACEHOLDER.PLACEHOLDER_NO_IMAGE
  placeholder_url: string = environment.ASSETS_BASE_URL + environment.IMAGE_PLACEHOLDER.PLACEHOLDER_GENERAL


  private destroy:any = new Subject();
  constructor(
    public pageService: PageService,
    public metaService: MetaService,
    private el: ElementRef,
    private renderer: Renderer2,
    public actionManager: ActionManager,
    public resourcePermissionService: ResourcePermissionService
  ) {
      super(metaService, pageService, resourcePermissionService)
  }
  ngOnInit(): void {
    // console.log("image onInit", this.widgetMeta, "cardImage", this.cardImage)
    super.ngOnInit()
    // this.image.src = this.widgetMeta.config.src.value
    this.destroy = this.metaService?.$contextChanged.subscribe((contextActions: any) => {
      if(contextActions && this.widgetMeta.id == contextActions?.widgetId){
        // console.log("context changed", contextActions)
        this.action(contextActions)
      }
    })

    if(this.cardImage) this.adjustDimensionsInCard();
    this.setContextActions()
    this.setStyle()
  }

  ngOnDestroy(): void {
    this.destroy?.unsubscribe();
  }

  ngDoCheck(): void {
    // console.log("do check fired: old", JSON.parse(JSON.stringify(this.oldMeta || {})), "new", JSON.parse(JSON.stringify(this.widgetMeta || {})))
    if((!this.oldMeta && this.widgetMeta) || (JSON.stringify(this.oldMeta) != JSON.stringify(this.widgetMeta))){
      // console.log("change detected in widgetMeta")
      this.oldMeta = JSON.parse(JSON.stringify(this.widgetMeta))
      this.checkAndSetSources()
      this.setStyle()
    }
  }

  checkAndSetSources(){
    // console.log("check and set sources", this.widgetMeta)
    // console.log("typeof config.src.value", typeof this.widgetMeta.config.src.value)
    // console.log("isArray src", Array.isArray(this.widgetMeta.config.src.value))
    // check src type
    if(typeof this.widgetMeta.config.src.value == 'string'){
      this.imgSrc = this.widgetMeta.config.src.value
      if (this.imgSrc == this.placeholder_url) this.isLoading = true
      else this.isLoading = false

    }else if(typeof this.widgetMeta.config.src.value == 'object' && WidgetUtilityService.isIterable(this.widgetMeta.config.src.value)){
      // console.log("else part")
      this.isMulti = true
      this.srcArr = []
      for (const key in this.widgetMeta.config.src.value) {
        // console.log("dealing", this.widgetMeta.config.src.value[key])
        this.srcArr.push(this.widgetMeta.config.src.value[key])
      }
      // console.log("sources set", this.srcArr)
    }
  }



  adjustDimensionsInCard(){
    // if(this.cover){}
    if(this.widgetMeta.config.aspectRatio?.value){
      let parts = this.widgetMeta.config.aspectRatio?.value?.split('/')
      console.log("parts", parts)
      if(!parseInt(parts[0]) || !parseInt(parts[1])) {
        console.log("invalid aspect ratio")
        return
      }
      this.localStyles['aspect-ratio'] = this.widgetMeta.config.aspectRatio?.value
      console.log("aspect ratio set", this.localStyles)
    } else {
      this.localStyles['aspect-ratio'] = 'auto'
    }
    if(this.widgetMeta.config.width?.value){
      this.localStyles['width'] = this.widgetMeta.config.width?.value || '100%'
    }
  }

  setStyle(){
    if(this.widgetMeta.config.width?.value){
      this.localStyles['width'] = this.widgetMeta.config.width?.value || '100%';
      const element = this.el?.nativeElement?.querySelector('.image');
      if (element && this.localStyles) {
        Object.keys(this.localStyles).forEach(style => {
          this.renderer.setStyle(element, style, this.localStyles[style]);
        });
      }
    }
  }

  imageLoad(i?: number) {

    if(this.cardImage) return
    if(this.isMulti) return

    /**
    * # --- initial rendering:
    * 1. let the image take up width based on gridX and height adjusted natually based on aspect ratio
    * 2. based on gridX vs rendered width ratio, calculate grid width (gridWidth = renderedWidth / gridX)
    * 3. get the rendered height and calculate how many vertical grid height it has taken round(renderedHeight / gridWidth)
    * 4. set it as gridY and adjust slight height difference in pixel due to rounding
    *
    * # --- for subsequent rendering when gridX and gridY both are present
    * 1. let the widget render naturally in X axis and take up width as per widgetMeta.gridX
    * 2. based on gridX vs rendered width ratio, calculate grid width (gridWidth = renderedWidth / gridX)
    * 3. calculate effective height as gridY * gridWidth   (height and width of grid will be same, i.e. square grid)
    * 4. apply effective height on the widget element
    */

    this.initialRenederedWidth = this.cover.nativeElement.clientWidth
    this.initialRenederedHeight = this.cover.nativeElement.clientHeight
    // console.log("rendered image", this.cover)
    // console.log("self clientWidth", this.cover.nativeElement.clientWidth)
    // console.log("self clientHeight", this.cover.nativeElement.clientHeight)
    /**
     * from the auto rendered height, calculate gridY
     */
    if (this.widgetMeta.gridY == 0) {
      this.initialGridY = (this.widgetMeta.gridX / this.initialRenederedWidth) * this.initialRenederedHeight
      let gridXWidth = this.initialRenederedWidth / this.widgetMeta.gridX
      this.widgetMeta.gridY = Math.round(this.initialGridY)
      this.widgetMeta.initialGridY = this.widgetMeta.gridY    // set original gridY for reset resize purpose
      this.widgetMeta.initialGridX = this.widgetMeta.gridX    // set original gridX for reset resize purpose
      let effectiveHeight = Math.round(gridXWidth * this.initialGridY)
      this.applyImageHeight.next(effectiveHeight)

      // console.log("gridX width", gridXWidth)
      // console.log("applied height", this.cover.nativeElement.clientHeight)
      console.log("setting gridY :", this.widgetMeta.gridY)
      // console.log("effective height", effectiveHeight)

      this.newWidgetMeta.emit(this.widgetMeta)
    }
    /**
     * from rendered grid width, calculate effective pixel height
     */
    else {
      let newHeight = Math.floor((this.initialRenederedWidth / this.widgetMeta.gridX) * this.widgetMeta.gridY)
      this.applyImageHeight.next(newHeight)

      // console.log("gridX", this.widgetMeta.gridX)
      // console.log("gridY", this.widgetMeta.gridY)
      // console.log("grid width", this.initialRenederedWidth / this.widgetMeta.gridX)
      // console.log("applying height :", newHeight)
      // console.log("applied height :", this.cover.nativeElement.clientHeight)
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    // console.log("changes in Image", changes)
    if(changes.contextActions?.currentValue){
      this.action(changes.contextActions.currentValue)
    }
    // if(changes.widgetMeta?.currentValue){
    //   console.log("OnChanges widget meta changed", changes.widgetMeta.currentValue)
    //   // this.checkAndSetSources()
    //   this.setContextActions()
    // }
  }

  //-----------------------------METHODS---------------------------------

  async onClick(event) {
    console.log("image clicked", event)
    this.widgetSelection.emit(this.widgetMeta.id)
    let res = await this.executeAction(event)
    this.onExecuteAction.emit(res);
  }

  async executeAction(e) {
    if(!this.widgetMeta.actionConfig || !this.widgetMeta.actionConfig.actions) return
    if (!this.builderMode){
      let res = await this.actionManager.executeActions(this.widgetMeta.actionConfig.actions, e);
      return res
    }
    //  this.actionManager.getAction(this.widgetMeta.actionConfig.action).doAction(this.widgetMeta.actionConfig, e)
  }


  setContextActions(){
    this.contextMenuActions = {
      actions: [
        "src",
        // "height",
        // "width",
        // "imageAlignment",
        "resetResize",
        "resetSkew",
        "borderRadius",
        "edit"
      ],
      src: {
        type: this.widgetMeta?.config.src.type
      },
      height: { type: this.widgetMeta?.config?.height?.type },
      // width: { type: this.widgetMeta?.config?.width?.type },
      // imageAlignment: { type: this.widgetMeta?.config?.imageAlignment?.type },
      resetResize: { type: 'reset-resize'},
      resetSkew: { type: 'reset-skew' },
      borderRadius: { type: this.widgetMeta?.config?.borderRadius?.type }
    }
    if(this.cardImage){
      this.contextMenuActions = {
        actions: ['width', 'imageAlignment'],    //'aspectRatio'
        // aspectRatio: { type: this.widgetMeta?.config?.aspectRatio?.type },
        width: {
          value: this.widgetMeta?.config.width?.value,
          type: this.widgetMeta?.config.width?.type
        },
        imageAlignment: {
          value: this.widgetMeta?.config.imageAlignment?.value,
          type: this.widgetMeta?.config.imageAlignment?.type
        }
      }
    }
    // console.log("card image", this.cardImage)
    // console.log("emitting context menu actions", this.contextMenuActions)
    this.raiseContextMenuActions.emit(this.contextMenuActions)
  }

  action(event) {
    console.log("action is", event)

    switch (event.actionType) {
      case "delete":
        this.onDelete()
        break;
      case "reset-skew":
        console.log("action:", event)
        if((this.widgetMeta.initialGridX != this.widgetMeta.gridX) && (this.widgetMeta.initialGridY == this.widgetMeta.gridY)){
          // console.log("X axis skewing")
          this.widgetMeta.gridX = this.widgetMeta.initialGridX
        } else if((this.widgetMeta.initialGridY != this.widgetMeta.gridY) && (this.widgetMeta.initialGridX == this.widgetMeta.gridX)){
          this.widgetMeta.gridY = this.widgetMeta.initialGridY
          // console.log("Y axis skewing")
          this.recalculateImageHeight()
        } else {
          console.log("both way skewing")
          let xChangePercent = (Math.abs(this.widgetMeta.initialGridX - this.widgetMeta.gridX) / this.widgetMeta.initialGridX) * 100
          let yChangePercent = (Math.abs(this.widgetMeta.initialGridY - this.widgetMeta.gridY) / this.widgetMeta.initialGridY) * 100
          console.log("x%", xChangePercent)
          console.log("y%", yChangePercent)

          if(xChangePercent > yChangePercent){
            let newX = Math.round((this.widgetMeta.initialGridX / this.widgetMeta.initialGridY) * this.widgetMeta.gridY)
            console.log("newX", newX)
            if(newX > 12){
              console.log("cant apply, newX > 12")
            }else{
              this.widgetMeta.gridX = newX
            }

          }else if(xChangePercent < yChangePercent){
            let newY = Math.round((this.widgetMeta.initialGridY / this.widgetMeta.initialGridX) * this.widgetMeta.gridX)
            console.log("newY", newY)
            let baseHeight = this.getBaseImageHeight()
            let newHeight = Math.round((baseHeight / this.widgetMeta.initialGridY) * newY)
            console.log("new effective height", newHeight)
            this.applyImageHeight.next(newHeight)

          }else{
            console.log("already in aspect ratio")
            // already maintains aspect ratio
          }
        }
        this.newWidgetMeta.emit(this.widgetMeta)
        break;

      case "reset-resize":
        console.log("action:", event)
        let baseHeight = this.getBaseImageHeight()
        this.widgetMeta.gridX = this.widgetMeta.initialGridX
        this.widgetMeta.gridY = this.widgetMeta.initialGridY
        this.newWidgetMeta.emit(this.widgetMeta)
        this.applyImageHeight.emit(baseHeight)
        break;

      case "resize":
        console.log("action:", event)
        this.widgetMeta = event.data
        console.log("new widgetMeta", this.widgetMeta)
        this.newWidgetMeta.emit(this.widgetMeta)
        break;

      case "customPropertyUpdate":
        this.widgetMeta.config[event.propertyName].value = event.data.config[event.propertyName].value;
        this.setStyle()
        this.newWidgetMeta.emit(this.widgetMeta)
        break;

      default:
        break;
    }
  }

  recalculateImageHeight(){
    this.initialRenederedWidth = this.cover.nativeElement.clientWidth
    this.initialRenederedHeight = this.cover.nativeElement.clientHeight
    console.log("rendered image", this.cover)
    console.log("self clientWidth", this.cover.nativeElement.clientWidth)
    console.log("self clientHeight", this.cover.nativeElement.clientHeight)

    // this.initialGridY = (this.widgetMeta.gridX / this.initialRenederedWidth) * this.initialRenederedHeight
    let gridXWidth = this.initialRenederedWidth / this.widgetMeta.gridX
    // this.widgetMeta.gridY = Math.round(this.initialGridY)
    let effectiveHeight = Math.round(gridXWidth * this.widgetMeta.gridY)
    console.log("new effective height", effectiveHeight)
    this.applyImageHeight.next(effectiveHeight)
  }

  getBaseImageHeight(){
    let currentWidth = this.cover.nativeElement.clientWidth
    console.log("current width", currentWidth, "current gridX", this.widgetMeta.gridX)

    let gridXWidth = currentWidth / this.widgetMeta.gridX
    let baseHeight = Math.round(gridXWidth * this.widgetMeta.initialGridY)
    return baseHeight
  }

  onDelete() {
    console.log("widget ID", this.widgetMeta.id, "will be deleted")
    this.widgetDeletion.emit(this.widgetMeta.id)
  }

}
