import { AfterViewInit, Component, DoCheck, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntypedFormControl, FormGroup } from '@angular/forms';
import { BoxService } from 'src/app/bloom/services/box-service.service';
import { PageService } from 'src/app/bloom/services/page-service.service';
import { AutomationService } from 'src/app/bloom/services/automation.service';
import { first } from 'rxjs/operators';

interface FORM_PANEL_FILTERS {
  filterEnabled?: boolean,
  filterItems?: FILTER_ITEM[]
}
interface FILTER_ITEM {
  attribute: any,
  operator: string,
  value: any,
  dataType: string,
  filterType: string
}

@Component({
  selector: 'app-form-panel-dialog',
  templateUrl: './form-panel-dialog.component.html',
  styleUrls: ['./form-panel-dialog.component.scss']
})
export class FormPanelDialogComponent implements OnInit {

  pageMeta: any;
  panelMeta: any;
  filteredAttributes: any;
  selectedConnectionId: any;
  selectedBoxName: any;
  selectedBoxId: any;
  selectedBoxObjectId: any;
  isBoxObjectSelected: boolean = false;
  isReadyToSave: boolean = false;
  isAttributesReady: boolean = false
  boxObjects: any;
  boxObjectAttributes: any[] = []
  boxObjectAttributesBackup: any[] = []
  isReceivedBoxObjects: boolean = false;
  canGetBoxObjects: boolean = false;

  attributeOptions: any[] = []
  getFnOptions: any[] = []
  actionFnOptions: any[] = []

  attributeOptionsError: boolean = false;
  faultyAttrOptions: any[] = []
  getOptionsError: boolean = false;
  actionOptionsError: boolean = false;
  faultyGetOptions: any[] = []
  faultyActionOptions: any[] = []
  availableInputParams: any = {
    options: {}
  }
  connectionConfig: any

  boxFunctions: any[] = []
  objectFuntions: any[] = []
  firstHit: boolean = false
  configChanged: boolean = false;   // this flag tells whether the existing configuration is changed (applies when !firstHit)
  attributeSpinner: boolean = false
  boxFunctionSpinner: boolean = false
  gettingObjFunSpinner: boolean = false
  saveDisabled: boolean = false
  disableBoxObjectInput: boolean = false
  refreshObjects: boolean = false
  boxConfigToken: any
  hoveredIndex: number = -1
  submitButtonTitle: string = 'Submit'

  terminationError: boolean = false;
  terminationErrorMessage: string;
  lockLoadInitialData: boolean = false;
  boxAttributes: any;

  isBoxConfigError: boolean = false;
  isBoxObjectConfigError: boolean = false;
  isAttributeError: boolean = false;
  errorMessage: any = ''

  formAttributes: any[] = []
  formPanelTitle: string = ''
  unselectedFormAttributes: any[] = []

  primaryAttribute: any
  isPrimaryExists: boolean = false
  primaryAttributeName: string

  spinner: boolean = false
  submitButtonMeta: any
  actionConfig: any
  actionFields: any = [];

  selectedSourceBoxId: any
  selectedSourceConnectionId: any
  selectedSourceBoxObject: any
  selectedSourceBoxName: any
  selectedAction: any
  isSourceAppSelected: boolean = false
  isSourceBoxSelected: boolean = false
  sourceBoxConfigToken: any
  canGetSourceBoxObjects: boolean = false
  sourceBoxFunctions: any[] = []
  // selectedBoxObjectId: any
  isSourceBoxObjectSelected: boolean = false

  collectAttrOptions: boolean = false
  collectGetOptions: boolean = false
  collectActionOptions: boolean = false
  // attrInputOptionsFn: any
  // otherInputOptionsFn: any
  getAttrFn: any
  getFn: any
  actionFn: any
  // inputOptions: any
  actionChanged: boolean = false;
  boxChanged: boolean = false;
  boxObjectChanged: boolean = false;

  attributeDisplayName: string;

  filter: FORM_PANEL_FILTERS = {
    filterEnabled: false,
    filterItems: []
  }

  selectAttributeControl = new UntypedFormControl();

  widgetTypeMap: any = {
    widgets: ['input', 'textarea', 'image', 'imageinput', 'checkbox', 'datetime', 'date', 'time', 'richtext', 'select', "chips", "autocomplete", 'numberinput', 'connection', 'star', 'slider', 'duration', 'period'],
    input: { name: 'Text Input', icon: 'text_fields' },
    textarea: { name: 'Text Area', icon: 'edit_note' },
    image: { name: 'Image', icon: 'image' },
    checkbox: { name: 'Check Box', icon: 'check_box' },
    numberinput: { name: 'Number input', icon: '123' },
    datetime: { name: 'Date + Time', icon: 'event' },
    date: { name: 'Date', icon: 'calendar_month' },
    time: { name: 'Time', icon: 'schedule' },
    imageinput: { name: 'Image Input', icon: 'file_upload' },
    richtext: { name: 'Rich Text', icon: 'wysiwyg' },
    select: { name: 'Dropdown', icon: 'arrow_drop_down' },
    chips: { name: 'Chips', icon: 'developer_board' },
    autocomplete: { name: 'Autocomplete', icon: 'manage_search' },
    star: { name: 'Star', icon: 'star' },
    slider: { name: 'Slider', icon: 'linear_scale' },
    duration: { name: 'Duration', icon: 'timer' },
    period: { name: 'Period', icon: 'date_range' },
    connection: { name: 'Connection', icon: 'cable' }
  }

  constructor(
    public dialogRef: MatDialogRef<FormPanelDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private boxService: BoxService,
    private pageService: PageService,
    private automationService: AutomationService
  ) {
    this.panelMeta = JSON.parse(JSON.stringify(data.panelMeta))
    this.pageMeta = data.pageMeta

  }

  ngOnInit(): void {
    if (this.data.firstHit) {
      this.firstHit = true
      // this.submitButtonMeta = this.createSubmitButton()
      this.actionConfig = this.automationService.createActionConfig()
      console.log("first hit! building new form panel...")
    } else {
      this.firstHit = false;
      console.log("panel meta received in form panel onInit:", this.panelMeta)
      this.formAttributes = this.panelMeta.formAttributes

      this.selectedBoxId = this.panelMeta.boxId
      this.selectedBoxName = this.panelMeta.boxName
      this.selectedConnectionId = this.panelMeta.connectionId
      this.selectedBoxObjectId = this.panelMeta.boxObjectId
      this.boxConfigToken = this.panelMeta.boxConfigToken;
      this.submitButtonTitle = this.panelMeta.submitButtonTitle

      // this.submitButtonMeta = this.getSubmitButtonMeta()
      this.submitButtonMeta = this.automationService.getSubmitButtonMeta(this.panelMeta)//this.panelMeta.widgets[this.panelMeta.widgets.length - 1] // last widget
      this.actionConfig = this.submitButtonMeta.actionConfig

      this.filter = this.panelMeta?.filter || this.filter
      console.log("filter", this.filter)

      this.findPrimaryAttribute()

      this.formPanelTitle = this.panelMeta.formPanelTitle

      if (this.panelMeta.attributeOptions?.length) {
        this.attributeOptions = this.panelMeta.attributeOptions
        // this.isOptionsToCollect = true
      }
      if (this.panelMeta.getFnOptions && this.panelMeta.getFnOptions.length) {
        this.getFnOptions = this.panelMeta.getFnOptions
        // this.isGetOptionsToCollect = true
      }
      if (this.panelMeta.actionFnOptions && this.panelMeta.actionFnOptions.length) {
        this.actionFnOptions = this.panelMeta.actionFnOptions
        // this.isActionOptionsToCollect = true
      }
      this.isBoxObjectSelected = true
      // this.isAttributeReady = true

      // this.getAttributes()
    }
  }

  getSubmitButtonMeta(){
    // find submit meta based on '_submit' suffix in widget id
    let submitButton = this.panelMeta.widgets.find(wid => (wid.id.split("_").length > 1) && (wid.id.split("_")[1] == 'submit'))
    if(submitButton) return submitButton

    let lastWidget = this.panelMeta.widgets[this.panelMeta.widgets.length - 1] // last widget
    return lastWidget
  }

  findPrimaryAttribute() {
    let i = this.formAttributes.findIndex(attr => attr.primary)
    if (i > -1) {
      this.primaryAttributeName = this.formAttributes[i].name
      this.isPrimaryExists = true
      return true
    } else {
      return false
    }
  }

  async getAttributes() {
    this.attributeSpinner = true
    let response
    console.log("[GET ATTRIBUTES] attributeOptions", this.attributeOptions)
    try {

      let boxId = this.selectedBoxId == 'starch' ? this.panelMeta.baseMap?.box_id : this.selectedBoxId;
      let conType = this.selectedBoxId == 'starch' ? "token": '';
      let conKey = this.selectedBoxId == 'starch' ? this.boxConfigToken : this.selectedConnectionId;
      response = await this.boxService.getAttributes(conKey, boxId, this.selectedBoxObjectId, this.attributeOptions, null, conType)
    } catch (err) {
      console.error("form-panel-dialog component: getAttributes()", err)
      this.isAttributeError = true
      this.errorMessage = JSON.stringify(err)
      return
    }

    this.boxObjectAttributes = response.result
    console.log("attributes received:", this.boxObjectAttributes)

    this.processAttributes()
    this.attributeSpinner = false
  }

  processAttributes() {
    console.log("boxOBjectAttrs in process attributes", this.boxObjectAttributes)
    // add extra fields
    this.boxObjectAttributes.forEach(attr => {
      attr['enabled'] = this.firstHit ? true : false
      attr['widgetType'] = 'input'
      attr['isDrillDown'] = false
      attr['editable'] = true
    })

    console.log("augmented attributes", this.boxObjectAttributes)

    // if config has changed
    if (this.boxChanged || this.boxObjectChanged) {
      console.log("else part")
      this.formAttributes = [...this.boxObjectAttributes]
      console.log("form attrs", this.formAttributes)
      console.log("box object attrs", this.boxObjectAttributes)

      this.findPrimaryAttribute()

      //check if at least one list attribute found
      if (this.formAttributes.length) {
        this.isReadyToSave = true
        this.saveDisabled = false
      } else {
        this.isAttributeError = true
        this.errorMessage = 'no attributes found for selected box'
      }
    } else {
      this.formAttributes = [...this.panelMeta.formAttributes]

      // ============== REORDER FORM ATTRIBUTES BASED ON ORDERING OF WIDGETS =================
      let tempFormAttributes = []
      let reordereedAttrIds = []
      this.panelMeta.widgets?.forEach(wid => {
        let attrIdFromWidget = wid.id.split('-').length > 1 ? wid.id.split('-')[1] : null
        if(attrIdFromWidget) reordereedAttrIds.push(attrIdFromWidget)
      })
      console.log("attr order based on widgets", reordereedAttrIds)
      if (reordereedAttrIds.length > 0){
        reordereedAttrIds.forEach(attributeId => {
        tempFormAttributes.push(this.formAttributes.find(attr => attr.__id == attributeId ))
        })
        console.log("temp formAttributes", tempFormAttributes)
        this.formAttributes = tempFormAttributes
      }
      // =====================================================================================

      console.log("form attributes assigned", this.formAttributes)
      this.isReadyToSave = true
      this.saveDisabled = false
    }

    console.log("formattrs", this.formAttributes)

    if (!this.firstHit && (!this.boxChanged || !this.boxObjectChanged)) {
      // merge formAttributes and non-listed attributes for a master list
      this.formAttributes = this.mergeAttributes(this.boxObjectAttributes, this.formAttributes)
    } else if (this.firstHit) {
      this.formAttributes = this.boxObjectAttributes
    }

    console.log("form attributes", this.formAttributes)
    // this.isAttributesReady = true;
    // this.constructGetOptions()
  }


  filterChanged(event){
    console.log("filter changed", event)
    this.panelMeta.filter = event;
  }

  mergeAttributes(boxObjectAttrs: any[], formAttributes: any[]) {
    console.log("MERGE ATTRIBUTES")
    let masterList: any[] = formAttributes
    boxObjectAttrs.forEach(attr => {
      let formIndex = masterList.findIndex(formAttr => formAttr.__id == attr.__id)
      if (formIndex == -1) {
        masterList.push(attr)
      }
    })
    return masterList
  }

  /**
   * creates a new attributes list by excluding already selected from all attributes
   */
  excludeSelected() {
    let arr = []
    arr = JSON.parse(JSON.stringify(this.formAttributes))

    let unselected: any = []
    this.boxObjectAttributes.forEach(attr => {
      // console.log("dealing ", attr)
      let selected: boolean = false
      arr.forEach(formAttr => {
        if (formAttr.__id == attr.__id) {
          // console.log("found selected")
          selected = true
        }
      });
      if (!selected) {
        unselected.push(attr)
      }
    })
    // console.log("unselected array:", unselected)
    this.unselectedFormAttributes = unselected
  }

  appSelected(e) {
    console.log("appselected", e);
    this.actionConfig.actions[0].actionMap.boxId = e.__id;
    this.selectedBoxId = e.__id

    this.boxService.setBoxObjectDisplayNames(this.selectedBoxId)
    this.attributeDisplayName = this.boxService.attributeDisplayName;
  }
  sourceAppSelected(e) {
    console.log("sourceAppselected", e);
    this.selectedSourceBoxId = e.__id
  }

  actionSelected(action) {
    console.log("actionSelected", action);
    this.actionConfig.actions[0].actionMap.action = action.__id
    let existingActionId
    if(!this.firstHit){
      existingActionId = this.panelMeta.submitButtonMeta.actionConfig.actions[0].actionMap.action
      console.log("EXISTING ACTION ID", existingActionId)
      if(!this.firstHit && existingActionId != action.__id){
        console.log("action changed, new action", action.__id)
        this.actionChanged = true
      }
    }
    this.selectedAction = action
    this.constructAttrOptions()
  }

  /** --------------------------------------boxSelected---------------------------
   * fires when a box is selected in box-select component
   * @param box the selected box will come from boxSelect component's output boxInput
   */
  async boxSelected(box: any) {
    console.log("selected box", box)
    this.actionConfig.actions[0].actionMap.connection = box._id

    // check if config changed and keep flag
    if (box._id !== this.selectedConnectionId) {
      this.boxChanged = true
      console.log("[boxSelected] turning saveDisabled true")
      this.saveDisabled = true
      this.refreshObjects = true
      this.disableBoxObjectInput = true
    }

    this.selectedBoxName = box.name
    this.selectedBoxId = box.box_id
    this.boxConfigToken = box.box_token
    this.selectedConnectionId = box._id

    // this.boxChanged = true

    await this.getBoxFunctions()
  }

  getSelectedConnectionId(box){
    if(box != 'starch') return this.selectedConnectionId;
    return
  }

  getSelectedBoxId(){
    let box = this.selectedBoxId == 'starch' ? this.panelMeta.baseMap?.box_id : this.selectedBoxId;
    return box;
  }

  async baseSelected(base){

    this.actionConfig.actions[0].actionMap.connection = null;
    this.actionConfig.actions[0].actionMap.boxConfigToken = base.storage_token;
    this.actionConfig.actions[0].actionMap.baseId = base._id;;
    this.actionConfig.actions[0].actionMap.baseMap = {
      box_id: base.storage_box,
      base_id: base._id
    }

    if('starch' !== this.selectedBoxId){
      this.configChanged = true
      this.saveDisabled = true
      this.refreshObjects = true
      this.disableBoxObjectInput = true
    }

    this.selectedBoxId = 'starch';
    this.panelMeta.baseMap = {
      box_id: base.storage_box,
      base_id: base._id
    }
    this.panelMeta.baseId = base._id;
    this.boxConfigToken = base.storage_token;
    await this.getBoxFunctions(this.panelMeta.baseMap.box_id);
  }


  async getBoxFunctions(boxId?:any) {
    this.boxFunctionSpinner = true
    let res = await this.boxService.getBoxFunctions(boxId || this.selectedBoxId, this.boxConfigToken)
    console.log("box functions received", res)
    this.boxFunctionSpinner = false
    this.boxFunctions = res

    // if boxFuntions has getobjects, enable/render boxObject selection component
    if (!this.boxFunctions.find(fn => fn.__id == 'getobjects')) {
      console.log("can NOT get boxObjects")
      this.canGetBoxObjects = false
      this.isBoxObjectConfigError = true
      this.errorMessage = "'getobjects' not available in box functions"

    } else if (!this.boxFunctions.find(fn => fn.__id == 'getattributes')) {
      this.canGetBoxObjects = false
      this.isBoxObjectConfigError = true
      this.errorMessage = "'getattributes' not available for" + this.selectedBoxObjectId

    } else {
      console.log("can get boxObjects, will enable box object selection component")
      this.canGetBoxObjects = true
      this.isBoxObjectConfigError = false
      this.disableBoxObjectInput = false
    }
  }

  async sourceBoxObjectSelected(boxObject: any) {
    console.log("box object selected", boxObject)
    console.log("existing box object", this.selectedBoxObjectId)
    // check if config changed and keep flag
    if(boxObject.__id !== this.selectedBoxObjectId){
      // this.configChanged = true
      this.boxObjectChanged = true
      this.isAttributesReady = false
      console.log("[boxObjectSelected] turning saveDisabled true")
      this.saveDisabled = true
    }

    this.selectedBoxObjectId = boxObject.__id ? boxObject.__id : boxObject;
    this.isBoxObjectSelected = true

    // update form panel title
    this.formPanelTitle = this.formPanelTitle ? this.formPanelTitle :
      (this.selectedBoxName ? `${this.selectedBoxName} - ${this.selectedBoxObjectId} - Form` : `${this.selectedBoxObjectId} - Form`)

    this.gettingObjFunSpinner = true

    let boxId = this.selectedBoxId == 'starch' ? this.panelMeta.baseMap?.box_id : this.selectedBoxId;
    let conType = this.selectedBoxId == 'starch' ? "token": '';
    let conKey = this.selectedBoxId == 'starch' ? this.boxConfigToken : this.selectedConnectionId;
    this.objectFuntions = await this.boxService.getBoxObjectFuntions(boxId, this.selectedBoxObjectId, conKey, conType)
    this.gettingObjFunSpinner = false
    console.log("box object functions received", this.objectFuntions)

    let getFn: any = this.objectFuntions.find(fn => fn.__id == 'get')
    let getAttributesFunction = this.boxFunctions.find(fn => fn.__id == 'getattributes')

    if(!getAttributesFunction){
      console.log("getAttributes function not found")
      this.terminationError = true
      this.terminationErrorMessage = `'getAttributes' function not supported on ${this.selectedBoxObjectId} of ${this.selectedBoxId}.`

    } else if(!getFn) {
      console.log("get function not found")
      this.terminationError = true
      this.terminationErrorMessage = `'get' function not supported on ${this.selectedBoxObjectId} of ${this.selectedBoxId}.`

    } else if (!getFn?.options?.filter){
      /**
       * TODO
       * only create action is supported
       * filter the action selection AC accordingly
       */
    }
  }

  boxSelectionError(event) {
    console.error("box selection error", event)
    this.isBoxConfigError = true
    this.errorMessage = JSON.stringify(event)
  }

  boxObjectSelectionError(event) {
    console.error("box object selection error", event)
    this.isBoxObjectConfigError = true
    this.errorMessage = JSON.stringify(event)
  }

  // calculates if input parameters needed for fetching attributes
  async constructAttrOptions(){

    console.log("[CONSTRUCT ATTR OPTIONS]")
    this.getAttrFn = this.boxFunctions.find(fn => fn.__id == 'getattributes')

    if(this.firstHit) {
      console.log("[CONSTRUCT ATTR OPTIONS] - if 1")
      this.attributeOptions = this.pageService.checkOptionsToCollect(this.getAttrFn)
      this.attributeOptions = this.attributeOptions.filter(op => !op.hidden)

      // keep availableInputParams in sync with attribute Options
      this.attributeOptions.forEach(op => {
        this.availableInputParams.options[op.__id || op.name] = op.value
        // if(op?.hidden == true) this.attributeOptions.splice(this.attributeOptions.indexOf(op),1);
      })

      if(this.attributeOptions?.length){
        this.collectAttrOptions = true
      }else{
        await this.getAttributes();
        this.constructGetOptions()
      }

    } else if(!this.firstHit && (this.boxChanged || this.boxObjectChanged)){
      console.log("[CONSTRUCT ATTR OPTIONS] - if 2")
      this.attributeOptions = this.pageService.checkOptionsToCollect(this.getAttrFn)
      // keep availableInputParams in sync with attribute Options
      this.attributeOptions.forEach(op => {
        this.availableInputParams.options[op.__id || op.name] = op.value
        if(op?.hidden == true) this.attributeOptions.splice(this.attributeOptions.indexOf(op),1);
      })

      if(this.attributeOptions?.length){
        this.collectAttrOptions = true
      }else{
        await this.getAttributes();
        this.constructGetOptions()
      }

    } else {  // default flow for 2nd hit when options available
      console.log("[CONSTRUCT ATTR OPTIONS] - if 3")
      this.attributeOptions.forEach(op => {
        this.availableInputParams.options[op.__id || op.name] = op.value
        if(op?.hidden == true) this.attributeOptions.splice(this.attributeOptions.indexOf(op),1);
      })
      await this.getAttributes();
      if(this.attributeOptions?.length){
        this.collectAttrOptions = true
      }
      this.constructGetOptions()
    }

  }

  // calculates if input parameters needed for get fn
  constructGetOptions(){

    console.log("[CONSTRUCT GET OPTIONS]")

    // if action is create, directly call this.constructActionOptions()
    if(this.selectedAction?.__id?.includes('create')){
      console.log("[CONSTRUCT ATTR OPTIONS] no need to collect get options")
      this.constructActionOptions()
      return
    }
    this.getFn = this.objectFuntions.find(fn => fn.__id == 'get')

    if(this.firstHit){
      console.log("[CONSTRUCT GET OPTIONS] if (1)")
      this.getFnOptions = this.pageService.checkOptionsToCollect(this.getFn)
      this.getFnOptions = this.getFnOptions.filter(op => !op.hidden)
      if(!this.getFnOptions?.length) {
        this.constructActionOptions()
        return
      }
      this.getFnOptions?.forEach(el => {
        el.value = this.availableInputParams.options[el.__id] || el.defaultValue || ""
      })
      this.collectGetOptions = true

    } else if(!this.firstHit && (this.boxChanged || this.boxObjectChanged || this.actionChanged)){
      console.log("[CONSTRUCT GET OPTIONS] if (2)")
      this.getFn = this.objectFuntions.find(fn => fn.__id == 'get')
      this.getFnOptions = this.pageService.checkOptionsToCollect(this.getFn)
      if(!this.getFnOptions?.length) {
        this.constructActionOptions()
        return
      }
      this.getFnOptions?.forEach(el => {
        el.value = this.availableInputParams.options[el.__id] || el.defaultValue || ""
      })
      this.collectGetOptions = true

    } else{
      console.log("[CONSTRUCT GET OPTIONS] if (3)")
      if(!this.getFnOptions?.length){
        console.log("no options to collect, calling constructActionOptions() and return")
        this.constructActionOptions()
        return
      }
      this.getFnOptions.forEach(el => {
        // if some option already has value in availableInputParams, use it
        if(this.availableInputParams.options.hasOwnProperty(el.__id)){
          el.value = this.availableInputParams.options[el.__id]
        }else{ // if a new property, keep back in availableInputParams for further use in actionOptions
          this.availableInputParams.options[el.__id] = el.value
        }
      })
      console.log("availableInputParams", this.availableInputParams)

      console.log("making collectGetOptions true")
      this.collectGetOptions = true
      this.constructActionOptions()
    }
  }

  // initGetInputParams(){
  //   this.getFn = this.objectFuntions.find(fn => fn.__id == 'get')
  //   this.getFnOptions = this.pageService.checkOptionsToCollect(this.getFn)
  //   if(!this.getFnOptions?.length) {
  //     this.constructActionOptions()
  //     return
  //   }
  // }

  // calculates if input parameters needed for action fn
  constructActionOptions(){
    console.log("actionFnOptions", this.actionFnOptions)
    if(this.firstHit){
      console.log("if - 1")
      this.actionFnOptions = this.pageService.checkOptionsToCollect(this.selectedAction)
    }else if(!this.firstHit && (this.boxChanged || this.boxObjectChanged || this.actionChanged)){
      console.log("if - 2")
      this.actionFnOptions = this.pageService.checkOptionsToCollect(this.selectedAction)
      this.actionFnOptions = this.actionFnOptions.filter(op => !op.hidden)
    }else{
      console.log("if - 3")
      // keep this.actionFnOptions as it was received from panelMeta
      this.isAttributesReady = true
    }

    this.actionFnOptions?.forEach(el => {
      // if available in availableInputParams, use it
      if(this.availableInputParams?.options?.hasOwnProperty(el.__id)){
        el.value = this.availableInputParams.options[el.__id] || el.defaultValue || ""
      }else{  // if new input occurs in actionFn, keep back in availableInputParams
        let sourceObject = {}
        sourceObject[el.__id] = el.value
        Object.assign(this.availableInputParams.options, sourceObject)
      }
    })
    console.log("this.availableInputParams", JSON.parse(JSON.stringify(this.availableInputParams)))

    if(!this.actionFnOptions?.length) {
      this.isAttributesReady = true
      return
    }

    this.collectActionOptions = true
  }


  async attributeOptionsCollected(){
    await this.getAttributes();
    this.constructGetOptions()
  }

  /**
   * bound with event emitter to catch input parameters for getAttribute functions
   * @param attrInputParams
   */
  attrOptionInputsRecevied(attrInputParams){
    console.log("attribute input params received", attrInputParams)
    if(!attrInputParams.options) return
    Object.keys(attrInputParams.options).forEach(optionId => {
      this.attributeOptions.forEach(attrOp => {
        if(attrOp.__id == optionId){
          attrOp.value = attrInputParams.options[optionId]
        }
      })
      // update availableInputParams for further use
      this.availableInputParams['options'][optionId] = attrInputParams.options[optionId]
    })
    console.log("availableInputParams updated", this.availableInputParams)
    console.log("attribute options", this.attributeOptions)
  }

  /**
   * bound with event emitter to catch input parameters for other getFn functions
   * @param getFnInputParams
   */
  getOptionInputsRecevied(getFnInputParams){
    console.log("getFn input params received", getFnInputParams)
    if(!getFnInputParams?.options) return
    Object.keys(getFnInputParams.options).forEach(optionId => {
      this.getFnOptions.forEach(getOption => {
        if(getOption.__id == optionId){
          getOption.value = getFnInputParams.options[optionId]
        }
      })
    })
    console.log("get options", this.getFnOptions)
  }

  /**
   * bound with event emitter to catch input parameters for actionFn
   * @param actionInputParams
   */
  actionOptionInputsRecevied(actionInputParams){
    console.log("action input params received", actionInputParams)
    if(!actionInputParams?.options) return
    Object.keys(actionInputParams?.options)?.forEach(optionId => {
      this.actionFnOptions.forEach(actionOption => {
        if(actionOption.__id == optionId){
          actionOption.value = actionInputParams.options[optionId]
        }
      })
    })
    console.log("action options", this.actionFnOptions)
  }

  inputParamsCollected(){
    this.isAttributesReady = true
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.formAttributes, event.previousIndex, event.currentIndex);
  }

  widgetMenuClosed(event: any) {
    console.log("widget menu closed", event)
  }
  widgetTypeSelected(attrIndex: any, widgetType: string) {
    console.log("widgetType selected", widgetType)
    this.formAttributes[attrIndex]['widgetType'] = widgetType
    console.log("modified list attributes", this.formAttributes)
  }
  enabledChanged(checked: any, i: number) {
    console.log("enable toggle changed for", i, "value:", checked, this.formAttributes[i])
    this.formAttributes[i].enabled = checked
  }

  editableChanged(checked: boolean, i: number) {
    this.formAttributes[i].editable = checked
    console.log("checked changed", checked, "for", this.formAttributes[i])
  }

  hiddenChanged(checked: boolean, i: number) {
    this.formAttributes[i].hidden = checked
    console.log("hidden changed", checked, "for", this.formAttributes[i])
  }

  drillDownToggle(i){
    console.log("drill down toggle for ", this.formAttributes[i])
    if(!this.formAttributes[i].isDrillDown) this.formAttributes[i]['isDrillDown'] = true
    else this.formAttributes[i].isDrillDown = false
  }

  nestedValueWidgetSelected(widgetType, i, j){
    let attr = this.formAttributes[i]
    console.log(widgetType, "for", attr.__id, ".", attr['nestedProperties']?.[j]?.['path'])
    attr.nestedProperties[j]['widgetType'] = widgetType
    console.log("attr", attr)
  }

  addNestedproperty(i){
    let attr = this.formAttributes[i]
    if(!attr.nestedProperties){
      attr['nestedProperties'] = []
    }
    attr.nestedProperties.push({
      path: '',
      widgetType: 'input'
    })
    console.log("attr now", attr)
  }

  deleteNestedProperty(i, j){
    let attr = this.formAttributes[i]
    attr.nestedProperties.splice(j, 1)
    console.log("attr now", attr)
  }

  // checkAttributeOptionsProvided() {
  //   // check if all required getAttributes options provided
  //   this.faultyAttrOptions = []
  //   this.faultyGetOptions = []
  //   this.attributeOptions.forEach((option: any) => {
  //     if (option.required && !option['value']) {
  //       console.log("value for ", option.name, "not provided")
  //       this.attributeOptionsError = true
  //       this.faultyAttrOptions.push(option)
  //     }
  //   })
  //   if (!this.faultyAttrOptions.length) this.attributeOptionsError = false
  //   console.log("missing attribute options", this.faultyAttrOptions)
  // }
  // checkGetOptionsProvided() {
  //   if (this.panelMeta.loadInitialData) {
  //     // check if all required options provided for get function
  //     this.getFnOptions.forEach((option: any) => {
  //       if (option.required && !option['value']) {
  //         console.log("value for ", option.name, "not provided")
  //         this.getOptionsError = true
  //         this.faultyGetOptions.push(option)
  //       }
  //     })
  //     if (!this.faultyGetOptions.length) this.getOptionsError = false
  //     console.log("missing get options", this.faultyGetOptions)
  //   }
  // }

  // checkActionOptionsProvided() {
  //   if (this.panelMeta.loadInitialData) {
  //     // check if all required options provided for get function
  //     this.actionFnOptions.forEach((option: any) => {
  //       if (option.required && !option['value']) {
  //         console.log("value for ", option.name, "not provided")
  //         this.actionOptionsError = true
  //         this.faultyActionOptions.push(option)
  //       }
  //     })
  //     if (!this.faultyActionOptions.length) this.actionOptionsError = false
  //     console.log("missing action options", this.faultyActionOptions)
  //   }
  // }

  async saveConfig() {
    // console.log("Save config hit at list panel", this.panelMeta)

    // this.checkAttributeOptionsProvided()
    // this.checkGetOptionsProvided()
    // this.checkActionOptionsProvided()

    if (this.attributeOptionsError || this.getOptionsError) return

    this.panelMeta.name = this.formPanelTitle
    this.formAttributes = this.formAttributes.filter(attr => attr.enabled)
    this.panelMeta.connectionId = this.selectedConnectionId
    this.panelMeta.boxId = this.selectedBoxId
    this.panelMeta.boxName = this.selectedBoxName
    this.panelMeta.boxObjectId = this.selectedBoxObjectId
    this.panelMeta.boxConfigToken = this.boxConfigToken
    this.panelMeta.attributeOptions = this.attributeOptions
    this.panelMeta.actionFnOptions = this.actionFnOptions
    this.panelMeta.getFnOptions = this.getFnOptions
    this.panelMeta.formPanelTitle = this.formPanelTitle
    this.panelMeta.submitButtonTitle = this.submitButtonTitle
    this.panelMeta.primaryAttribute = this.boxObjectAttributes.find(attr => attr.primary)

    console.log("this.formAttributes", this.formAttributes)
    console.log("this.panelMeta", this.panelMeta)
    console.log("this.submitButtonMeta", this.submitButtonMeta)
    // augments and returns new panel meta with updated widget list, submit button meta with actionConfig
    this.panelMeta = this.automationService.generateFormWidgets(this.formAttributes, this.panelMeta, this.submitButtonMeta, this.actionConfig.actions[0].actionMap.action)
    this.panelMeta['submitButtonMeta'] =  this.automationService.getSubmitButtonMeta(this.panelMeta);//this.panelMeta.widgets[this.panelMeta.widgets.length - 1]

    this.panelMeta.formAttributes = this.formAttributes

    this.data = this.panelMeta
    console.log("save config in form panel dialog: will return data:", this.data)

    this.dialogRef.close(JSON.parse(JSON.stringify(this.data)))
  }
}
