<div
  class="outerCover"
  *ngIf="builderMode"
  [style.background-color]="widgetMeta.config.backgroundColor ? widgetMeta.config.backgroundColor.value : 'transparent'"
  [class.selectedNow]="selectedWidgetId == widgetMeta.id"
  [class.hoveredNow]="selectedWidgetId == widgetMeta.id? false: hoveredNow"
  (mouseenter)="widgetMouseenter()"
  (mouseleave)="widgetMouseleave()"
  (click)="onClick()"
  [matTooltip]="widgetMeta.config.tooltip && widgetMeta.config.tooltip.enabled ? widgetMeta.config.tooltip.value : ''"
  [matTooltipDisabled]="!widgetMeta.config.tooltip || !widgetMeta.config.tooltip.enabled"
>
  <widget-drag-handle [class.invisible]="(selectedWidgetId !== widgetMeta.id && !hoveredNow)"></widget-drag-handle>
  <div class="innerCover">
    <inline-title [widgetMeta]="widgetMeta" [builderMode]="builderMode" [styles]="styles" (widgetTitleChanged)="newWidgetMeta.emit(widgetMeta)"></inline-title>
    <!-- <div *ngIf="widgetMeta.config?.title?.value" fxLayout fxFlexFill [style.justify-content]="widgetMeta?.config?.alignment?.value">{{ widgetMeta.config.title.value }}</div> -->
    <duration-picker></duration-picker>
  </div>
</div>

<div
  class="outerCover"
  *ngIf="!builderMode"
  [style.background-color]="widgetMeta.config.backgroundColor ? widgetMeta.config.backgroundColor.value : 'transparent'"
  appearance="standard"
  [matTooltip]="widgetMeta.config.tooltip && widgetMeta.config.tooltip.enabled ? widgetMeta.config.tooltip.value : ''"
  [matTooltipDisabled]="!widgetMeta.config.tooltip || !widgetMeta.config.tooltip.enabled"
>
  <div class="innerCover">
    <inline-title [widgetMeta]="widgetMeta" [builderMode]="builderMode" [styles]="styles"></inline-title>
    <!-- <div *ngIf="widgetMeta.config?.title?.value" fxLayout fxFlexFill [style.justify-content]="widgetMeta?.config?.alignment?.value">{{ widgetMeta.config.title.value }}</div> -->
    <duration-picker></duration-picker>
  </div>
</div>
