<spinner [center]="true" size="la-2x" color="grey" type="ball-triangle-path"></spinner>

<div fxLayout="row">
  <div fxLayout="column">
    <div fxLayout="row" class="mat-h2" fxLayoutAlign="start center">
      Query Parameters
    </div>
    <div fxLayout="row" *ngFor="let data of queryData; let i = index" fxLayoutAlign="start center">
      <div fxLayout="row" fxLayoutGap="10px" id="body-key-value">
        <mat-form-field class="example-full-width" appearance="outline">
          <mat-label>Attribute</mat-label>
          <input matInput placeholder="Key" (ngModelChange)="inputAdded($event)" [(ngModel)]="data.__id" class="input-field">
        </mat-form-field>
        <mat-form-field class="example-full-width" appearance="outline">
          <mat-label>Value</mat-label>
          <input matInput placeholder="Value" (ngModelChange)="inputAdded($event)" [(ngModel)]="data.value" class="input-field">
        </mat-form-field>
        <app-field-picker  [inputFieldMap]="fieldMap"
            (selectedFields)="insertCursor($event, data)">
        </app-field-picker>
      </div>
      <button mat-icon-button matTooltip="Delete Query Parameter" (click)="deleteQueryParameter(i)" class="delete-icon">
        <mat-icon>delete</mat-icon>
      </button>
    </div>
    <div fxLayout="row" fxLayoutAlign="start center" *ngIf="isDynamicData">
      <button style="cursor: pointer;" (click)="addNewQueryParameter()" color="primary" mat-stroked-button matTooltip="Add query parameters">
        <mat-icon>add_circle_outline</mat-icon>
        Add Parameters
      </button>
    </div>
  </div>
</div>


