import { Injectable } from '@angular/core';
import { SystemFieldsService } from './system-fields.service';

@Injectable({
  providedIn: 'root'
})
export class ContextDataService {
  constructor(public systemFieldService: SystemFieldsService) {}

    formPanelLoadedData: any;

    results: any = {};

    clearContext(){
      this.results = {};
    }

    setResult(code: any, result: any) {
      this.results[code] = result;
    }

    getResult(code: any) {
      return this.results[code];
    }

    async initialiZeSystemField(){
      let systemFields = await this.systemFieldService.getSystemFields();
      return systemFields
    }

   async getContextData(pageModel?:any){
    let systemFields =await this.initialiZeSystemField()
    let allSystemFields = systemFields?.fields
    let obj = {}
    for(let i = 0; i < allSystemFields.length; i++){
      obj[allSystemFields[i]] = systemFields[allSystemFields[i]].value
    }
    pageModel.systemField = obj;
    pageModel.old = this.formPanelLoadedData || {};
    if(this.results) Object.assign(pageModel, this.results);
    console.log("[API-EXECUTION] PAGEMODEL PREPARED: ",pageModel)
    return pageModel;
   }
}
