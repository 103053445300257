import { WidgetUtilityService } from "../../services/widget-utility.service";
import { Widget } from "./baseWidget";
import { DEFAULT_VALUE } from "./dynamicDefaultValues";
import { TextFormatting } from "./textFormat";

export class DateInput extends Widget {
  type = 'date';
  gridX = 6;
  minGridX = 3;
  config = {
    props: [
      "placeholder",
      // "presetDate",
      'tooltip',
      'value',
      'defaultValue',
      'customOutputFormat',
      "hidden",
      'required',
      'viewOnly',
      'appearance',
    ],
    appearance: {
      displayName: "Input appearance",
      value: "standard",
      type: "select"
    },
    placeholder: {
      displayName: "Date Placeholder",
      value: "Enter date",
      type: 'text'
    },
    hidden: {
      displayName: 'Hidden',
      type: 'boolean',
      value: false,
    },
    tooltip: {
      displayName: 'Tooltip',
      value: "",
      type: 'text',
      enabled: true
    },
    customOutputFormat: {
      displayName: "Custom output date format",
      type: 'date-time-format-input',
      enabled: false,
      customDate: true,
      customDateFormat: 'MM-dd-YYYY',
    },
    required: {
      displayName: 'Required',
      type: 'boolean',
      value: false,
    },
    viewOnly: {
      displayName: 'View Only Mode',
      description: 'When turned on, user can not change the value',
      value: false,
      type: 'boolean',
      resetValue: false
    },
    value: {
      displayName: "Value",
      // value: `${String(new Date().getDate()).padStart(2, '0')}/${String(new Date().getMonth() + 1).padStart(2, '0')}/${new Date().getFullYear()}`,
      value: "",
      resetValue: '',
      type: 'text'
    },
    defaultValue: DEFAULT_VALUE,
  }

  //text formatting properties
  textFormat;

  constructor(id: number, name: string) {
    super(id, name)
    this.attachCommonConfig(this)

    this.config['title'].value = 'Choose a date'

     //initialize the styles with default values
    this.textFormat = TextFormatting.initStyles(JSON.parse(JSON.stringify(TextFormatting.textFormatting())))
  }
  public getWidgetConfig(){
    let map = {
      valuePath: "config.value.value"
    }
    return map;
  }

  createFormattedValue(rawDateTimeValue){
    if(!rawDateTimeValue) return ''
    console.log("raw value", rawDateTimeValue)
    console.log("in local time", WidgetUtilityService.toIsoString(new Date(rawDateTimeValue)))

    let val = WidgetUtilityService.toIsoString(new Date(rawDateTimeValue))
    return val || ''
  }

  public getWidgetMeta() {
    return this
  }
}
