<button matTooltip="Choose a field to insert"  mat-icon-button [matMenuTriggerFor]="menu" class="field-insert-button">
    <mat-icon>more_vert</mat-icon>
</button>
<mat-menu #menu="matMenu" class="field-insert-menu">
    <mat-accordion>
        <div *ngFor="let fieldObj of inputFieldMap?.list"> 
            <mat-expansion-panel (click)="onExpansionPanelClick($event)" [expanded]="isExpanded || fieldObj === 'sourceFields'" class="field-panel">
                <mat-expansion-panel-header class="field-panel-header">
                    <mat-panel-title class="field-panel-title">
                        <b>{{inputFieldMap[fieldObj]?.displayName}}</b>
                    </mat-panel-title>
                  </mat-expansion-panel-header>
                  <div *ngFor="let templSourceField of inputFieldMap[fieldObj]?.fields" >
                    <button mat-menu-item 
                    (click)="insertField(templSourceField)" class="field-item">
                    <mat-icon matTooltip="{{ templSourceField?.dataType }}" class="field-item-icon"> 
                        {{iconMap[templSourceField?.dataType]}} 
                    </mat-icon> <span class="field-item-text">{{ templSourceField?.name }}</span>
                    </button>
                </div>
            </mat-expansion-panel>
        </div>
    </mat-accordion>
    <!-- <div *ngFor="let fieldObj of inputFieldMap?.list">
        <div *ngIf="inputFieldMap[fieldObj]?.fields?.length > 0"  style="padding: 2px;">
            <div fxLayout="row" fxLayoutAlign="center center">
                <b>{{inputFieldMap[fieldObj]?.displayName}}</b>
            </div>
            <div *ngFor="let templSourceField of inputFieldMap[fieldObj]?.fields" >
                <button mat-menu-item 
                (click)="insertField(templSourceField)">
                <mat-icon matTooltip="{{ templSourceField?.dataType }}"> 
                    {{iconMap[templSourceField?.dataType]}} 
                </mat-icon> <span>{{ templSourceField?.name }}</span>
            </button>
            </div>
            <mat-divider></mat-divider>
        </div>
    </div> -->
</mat-menu>
