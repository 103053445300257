import { Widget } from "./baseWidget";
import { TextFormatting } from "./textFormat";

export class Period extends Widget {
  type = 'period';
  gridX = 6;
  minGridX = 3;

  config = {
    props: [
      "placeholder",
      'tooltip',
      'viewOnly',
      'value',
      'appearance',
    ],
    appearance: {
      displayName: "Input appearance",
      value: "standard",
      type: "select"
    },
    placeholder: {
      displayName: "Input Placeholder",
      value: "default input placeholder",
      type: 'text'
    },
    tooltip: {
      displayName: 'Tooltip',
      value: this.type,
      type: 'text',
      enabled: true
    },
    value: {
      displayName: "Value",
      value: "",
      resetValue: '',
      type: 'text'
    },
    viewOnly: {
      displayName: 'View Only Mode',
      description: 'When turned on, user can not change the value',
      value: false,
      type: 'boolean',
      resetValue: false
    }
  }

  //text formatting properties
  textFormat;

  constructor(id: number, name: string) {
    super(id, name)
    this.attachCommonConfig(this)
    this.config['title'].value = "Choose a date range"

    //initialize the styles with default values
    this.textFormat = TextFormatting.initStyles(JSON.parse(JSON.stringify(TextFormatting.textFormatting())))
  }
}
