<div
  *ngIf="builderMode"
  class="outerCover"
  [style.background-color]="widgetMeta.config.backgroundColor ? widgetMeta.config.backgroundColor.value : 'transparent'"
  [class.selectedNow]="selectedWidgetId === widgetMeta.id"
  [class.hoveredNow]="selectedWidgetId === widgetMeta.id? false: hoveredNow"
  (mouseenter)="widgetMouseenter()"
  (mouseleave)="widgetMouseleave()"
  (click)="onClick()"
  [matTooltip]="widgetMeta.config.tooltip && widgetMeta.config.tooltip.enabled ? widgetMeta.config.tooltip.value : ''"
  [matTooltipDisabled]="!widgetMeta.config.tooltip || !widgetMeta.config.tooltip.enabled"
  fxLayout fxLayoutAlign="center center"
>
  <widget-drag-handle [class.invisible]="(selectedWidgetId !== widgetMeta.id && !hoveredNow)"></widget-drag-handle>

  <div class="innerCover">
    <inline-title [widgetMeta]="widgetMeta" [builderMode]="builderMode" [styles]="styles" (widgetTitleChanged)="newWidgetMeta.emit(widgetMeta)"></inline-title>
    <mat-form-field fxFlexFill [appearance]="this.widgetMeta.config?.appearance?.value ? this.widgetMeta.config.appearance.value : 'standard'">
      <mat-label *ngIf="!widgetMeta.config.title?.show || !widgetMeta.config.title?.value?.length">{{ widgetMeta.config.placeholder.value || widgetMeta.name }}</mat-label>
      <input matInput type="datetime-local" [ngModel]="displayValue" [placeholder]="widgetMeta.config.placeholder.value" [required]="widgetMeta?.config?.required?.value ? widgetMeta.config.required.value : false" (input)="newInput($event)">
    </mat-form-field>
    <!-- [value]="value" -->
  </div>
</div>


<div
  *ngIf="!builderMode && !widgetMeta.config?.hidden?.value"
  class="outerCover"
  (click)="onClick()"
  [style.background-color]="widgetMeta.config.backgroundColor ? widgetMeta.config.backgroundColor.value : 'transparent'"
  [matTooltip]="widgetMeta.config.tooltip && widgetMeta.config.tooltip.enabled ? widgetMeta.config.tooltip.value : ''"
  [matTooltipDisabled]="!widgetMeta.config.tooltip || !widgetMeta.config.tooltip.enabled"
  fxLayout fxLayoutAlign="center center"
>
  <div class="innerCover" *ngIf="!widgetMeta.config.viewOnly || !widgetMeta.config.viewOnly.value">
    <inline-title [widgetMeta]="widgetMeta" [builderMode]="builderMode" [styles]="styles"></inline-title>
    <mat-form-field [fxFlex]="100" [appearance]="this.widgetMeta.config?.appearance?.value ? this.widgetMeta.config.appearance.value : 'standard'">
      <mat-label *ngIf="!widgetMeta.config.title?.show || !widgetMeta.config.title?.value?.length">{{ widgetMeta.config.placeholder.value || widgetMeta.name }}</mat-label>
      <input 
        matInput
        onfocus="this.showPicker()" 
        type="datetime-local" 
        [ngModel]="displayValue"
        [placeholder]="widgetMeta.config.placeholder.value"
        [required]="widgetMeta?.config?.required?.value ? widgetMeta.config.required.value : false" 
        (input)="newInput($event)"
        #datetimeInput="ngModel"
        >
        <!-- [value]="value" -->
       <!-- Error & Note handling -->
       <mat-error *ngIf="datetimeInput.invalid && datetimeInput.hasError('required')">Datetime is required</mat-error>
       <mat-hint *ngIf="widgetMeta.note">
         <span style="font-size: small;margin-left: 10px; color: gray;" *ngIf="widgetMeta.note?.message">Note: {{widgetMeta.note.message}}</span>
         <a *ngIf="widgetMeta.note?.knowMoreLink" style="font-size: small;" [href]="widgetMeta.note.knowMoreLink" target="_blank"> &nbsp;know more</a>
       </mat-hint>
    </mat-form-field>
  </div>
  <div *ngIf="widgetMeta.config.viewOnly && widgetMeta.config.viewOnly.value && widgetMeta.config.value.value"
    class="innerCover label-z-index content"
    [ngStyle]="styles"
  >
  <!-- {{ widgetMeta.config.placeholder.value }} {{ widgetMeta.config.placeholder.value ? ':&nbsp;' : ''}} {{displayValue || initValue() }} -->
    {{displayValue || initValue() }}
  </div>
</div>
