import { Subject } from "rxjs";
import { Widget } from "./baseWidget";
import { DEFAULT_VALUE } from "./dynamicDefaultValues";
import { WidgetUtilityService } from "../../services/widget-utility.service";
import { TextFormatting } from "./textFormat";

export class DateTime extends Widget {
  type = 'datetime';
  gridX = 6;
  minGridX = 3;
  config = {
    props: [
      "placeholder",
      'value',
      'tooltip',
      'defaultValue',
      "viewOnly",
      "required",
      "hidden",
      "customOutputFormat",
      "appearance",
    ],
    appearance: {
      displayName: "Input appearance",
      value: "standard",
      type: "select"
    },
    placeholder: {
      displayName: "DateTime Placeholder",
      value: "Enter date and time",
      type: 'text'
    },
    value: {
      displayName: "Value",
      value: '',
      type: 'datetime'
    },
    tooltip: {
      displayName: 'Tooltip',
      value: "",
      type: 'text',
      enabled: true
    },
    viewOnly: {
      displayName: 'View Only Mode',
      description: 'When turned on, user can not change the date-time value',
      value: false,
      type: 'boolean',
      resetValue: false
    },
    required: {
      displayName: 'Required',
      type: 'boolean',
      value: false,
    },
    hidden: {
      displayName: 'Hidden',
      type: 'boolean',
      value: false,
    },
    customOutputFormat: {
      displayName: "Custom output date-time format",
      // value: 'M/d/yy, h:mm a',
      type: 'date-time-format-input',
      enabled: false,
      customTime: true,
      customDate: true,
      customDateFormat: 'M/d/yy',
      customTimeFormat: 'h:mm a'
    },
    defaultValue: DEFAULT_VALUE,
  }

  //text formatting properties
  textFormat;

  constructor(id: number, name: string) {
    super(id, name)
    this.attachCommonConfig(this)
    this.config['title'].value = 'Choose date & time'

    //initialize the styles with default values
    this.textFormat = TextFormatting.initStyles(JSON.parse(JSON.stringify(TextFormatting.textFormatting())))
  }

  static dateTimeEvent = new Subject<any>();
  static dateTimeEvent$ = DateTime.dateTimeEvent.asObservable();

  public getEventSubscribe(data?:any){
    if(!data) return 1
    DateTime.dateTimeEvent.next(data);
    // DateTime.dateTimeEvent.complete();
  }

  public getMappingConfig(){
    let map = {
      __id: "${panel.id}.${widget.id}.value",
      name: "${panel.name} ${widget.name}",
      dataType: "datetime"
    }
    return map;
  }

  public getWidgetConfig(){
    let map:any = {
      valuePath: "config.value.value",
      effect: {
        styles: []
      }
    }
    map = this.mergeEffectStyle(map)
    return map;
  }

  public getWidgetMeta() {
    return this
  }

  createFormattedValue(rawDateTimeValue){
    if(!rawDateTimeValue) return ''
    // console.log("raw value", rawDateTimeValue)
    // console.log("in local time", WidgetUtilityService.toIsoString(new Date(rawDateTimeValue)))

    let val = WidgetUtilityService.toIsoString(new Date(rawDateTimeValue))
    return val || ''
  }

}
