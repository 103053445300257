import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AvailableOptions } from '../../models/widgetClasses/commonWidgetProps';

@Component({
  selector: 'app-button-type-selection',
  templateUrl: './button-type-selection.component.html',
  styleUrl: './button-type-selection.component.scss'
})
export class ButtonTypeSelectionComponent implements OnInit {
  @Input() widgetMeta;
  @Input() prop;
  @Input() isContextMenu: boolean = false;
  @Output() btnTypeSettingsChanged: EventEmitter<any> = new EventEmitter();

  optionsMap: any = {};

  ngOnInit() {
    this.initAvailableOptions();
  }

  initAvailableOptions(){
    this.widgetMeta.config.props.forEach((property: any) => {
      // console.log("dealing prop", property)
      if(this.widgetMeta.config[property].type == 'select' && AvailableOptions[property] && AvailableOptions[property].widgetsSupported.includes(this.widgetMeta.type)){
        this.optionsMap[property] = AvailableOptions[property]?.availableOptions || []
      }
    })
    // console.log("optionsMap initialized", this.optionsMap)
  }

  customPropertyChanged(event: any, propName: any) {
    let data = event.value
    // console.log("input detected", event)
    // console.log("propName", propName)
    // console.log("value", this.widgetMeta.config[propName].value)

    // handle stringified json input
    // data = this.widgetMeta.config[propName].type == 'json' ? JSON.parse(data) : data

    this.widgetMeta.config[propName].value = data
    // this.btnTypeSettingsChanged.emit(this.widgetMeta)
  }
}
