<mat-spinner *ngIf="spinner" color="warn" class="centerOfAll" [diameter]="50"
  fxLayoutAlign="center center"></mat-spinner>
<div class="outerCover"
  *ngIf="builderMode" [class.selectedNow]="selectedWidgetId == widgetMeta.id"
  [style.background-color]="widgetMeta.config.backgroundColor ? widgetMeta.config.backgroundColor.value : 'transparent'"
  [class.hoveredNow]="selectedWidgetId == widgetMeta.id? false: hoveredNow" (mouseenter)="widgetMouseenter()"
  (mouseleave)="widgetMouseleave()" (click)="onClick()"
  [matTooltip]="widgetMeta.config.tooltip && widgetMeta.config.tooltip.enabled ? widgetMeta.config.tooltip.value : ''"
  [matTooltipDisabled]="!widgetMeta.config.tooltip || !widgetMeta.config.tooltip.enabled"
>
  <widget-drag-handle [class.invisible]="(selectedWidgetId !== widgetMeta.id && !hoveredNow)"></widget-drag-handle>

  <inline-title [widgetMeta]="widgetMeta" [builderMode]="builderMode" [styles]="styles" (widgetTitleChanged)="newWidgetMeta.emit(widgetMeta)"></inline-title>
  <dropzone
    [maxFileSize]="2097152"
    style="width: 100%;"
    [pathId]="storePath"
    [disabled]="builderMode"
    [setImage]="existingImages"
    [width]="'95%'"
    [multiple]="widgetMeta.config.multi && widgetMeta.config.multi.value ? true : false"
    [displayText]="widgetMeta.config.placeholder?.value || ''"
    (mousedown)="$event.stopPropagation()"
    (fileChange)="userInputDetected($event)"
  >
  </dropzone>
</div>


<div class="outerCover" *ngIf="!builderMode" (click)="onClick()"
  [style.background-color]="widgetMeta.config.backgroundColor ? widgetMeta.config.backgroundColor.value : 'transparent'"
  [matTooltip]="widgetMeta.config.tooltip && widgetMeta.config.tooltip.enabled ? widgetMeta.config.tooltip.value : ''"
  [matTooltipDisabled]="!widgetMeta.config.tooltip || !widgetMeta.config.tooltip.enabled"
>
  <div fxLayout="row" fxFlex="100" fxLayoutAlign="center center">
    <div fxLayout="column" fxFlex="100" fxLayoutAlign="center center">
      <inline-title [widgetMeta]="widgetMeta" [builderMode]="builderMode" [styles]="styles"></inline-title>
      <dropzone
        style="width: 100%;"
        [width]="'95%'"
        [maxFileSize]="2097152"
        [pathId]="storePath"
        (mousedown)="$event.stopPropagation()"
        [setImage]="existingImages"
        [multiple]="widgetMeta.config.multi && widgetMeta.config.multi.value ? true : false"
        [displayText]="widgetMeta.config.placeholder?.value || ''"
        (fileChange)="userInputDetected($event)"
        (urlListChanged)="itemRemoved($event)"
      >
      </dropzone>
    </div>
  </div>
</div>
