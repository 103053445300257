import { ChangeDetectorRef, Component, NgZone, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { FormService } from '../form.service';
import { Router } from '@angular/router';
import { PageService } from 'src/app/bloom/services/page-service.service';
import { MatDialog } from '@angular/material/dialog';
import { AdminService } from 'src/app/modules/admin/admin.service';
import { v4 as uuidv4 } from 'uuid';
import hexRgb from 'hex-rgb';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MetaService } from 'src/app/bloom/services/meta-service';
import { MatExpansionPanel } from '@angular/material/expansion';

@Component({
  selector: 'app-form-themes',
  templateUrl: './form-themes.component.html',
  styleUrls: ['./form-themes.component.scss']
})
export class FormThemesComponent implements OnInit {

  form: any
  isThemesReady: boolean = true;
  isEditOpen: boolean = false;
  selectedTheme: any;
  newThemeMap: any;
  spinner: boolean;
  prevSelectedTheme: any;
  configureType: string;
  editIndex: any;
  isNameEditMode = false;
  openBackgroundPanel = false;
  openFrameBackgroundPanel = false;
  defaultFrameConfig: any =  {
    style: {
      "background-color": "#ffffff",
      "background-image": ""
    },
    image: {url: ""},
    "background-color": "#ffffff",
    opacity: 100,
    margin: {
      left: 15,
      right: 85,
      top: 2,
      bottom: 98
    }
  };
  draggingEndForMargin: string;
  buttonColorTypeOptions:any =  [
    "primary",
    "accent",
    "warn",
    "none",
    "custom"
  ]

  buttonTypeOptions:any =  [
    "Basic",
    "Raised",
    "Stroked",
    "Flat",
    "Fab"
  ]
  expandedIndex: number | null = null; // Track the currently expanded panel
  @ViewChildren(MatExpansionPanel) panels!: QueryList<MatExpansionPanel>;
  formMetaSub: any;
  constructor(
    public formService: FormService,
    public pageService: PageService,
    public router: Router,
    private adminService: AdminService,
    private _snackBar: MatSnackBar,
    private metaService: MetaService,
    private cdr: ChangeDetectorRef,
    private ngZone: NgZone
  ) {

  }


  ngOnInit(): void {
    this.formService.themeSubject.next(null);
    this.formMetaSub = this.formService.formMeta.subscribe(data => {
      if(this.form?.code != data?.code) this.form = data;
      // let selectedTheme = this.form?.theme?.selectedTheme ? JSON.parse(JSON.stringify(this.form?.theme?.selectedTheme)) : null;
      // this.prevSelectedTheme = selectedTheme;
      // this.formService.themeSubject.next(selectedTheme);
    })
    // if(!this.form) this.form = this.formService.formMeta.value;
    // let selectedTheme = this.form?.theme?.selectedTheme ? JSON.parse(JSON.stringify(this.form?.theme?.selectedTheme)) : null;
    // this.prevSelectedTheme = selectedTheme;
    // this.formService.themeSubject.next(selectedTheme);
    if(!this.form) return;
  }

  componentInits(){
    if(!this.form) this.form = this.formService.formMeta.value;
    console.log("themes init", this.form );
    this.openBackgroundPanel = false
    // if(this.form?.theme?.themes.length > 0) {
    //   this.selectedTheme = this.form?.theme?.selectedTheme || null;
    // } else this.selectedTheme = null;

    let selectedTheme = this.form?.theme?.selectedTheme ? JSON.parse(JSON.stringify(this.form?.theme?.selectedTheme)) : null;
    this.prevSelectedTheme = selectedTheme;
    this.formService.themeSubject.next(selectedTheme);

    // if(!this.selectedTheme){
    //   this.configureType = null;
    //   this.createNewTheme();
    // }
    // else {
    //   let condition = theme => theme.code === this.selectedTheme.code;
    //   let index = this.form?.theme?.themes?.findIndex(condition);
    //   this.editTheme(this.selectedTheme, index)
    // }
  }

  addNewTheme(){
    this.form = this.formService.formMeta.value;
    this.isThemesReady = false;
    this.setPageButton(this.newThemeMap)
    // console.log("this.configureType", this.configureType)
    if(this.configureType == "edit"){
      this.form.theme.themes[this.editIndex] = this.newThemeMap;
    } else {
      this.form.theme.themes.push(this.newThemeMap);
      this.form.theme.selectedTheme = this.newThemeMap;
      this.formService.themeSubject.next(this.form.theme.selectedTheme);
    }
    // console.log("this.form.theme.themes", this.form.theme.themes)
    this.newThemeMap = null;
    // this.closeAllPanels();
    this.formService.preventAutoSave = false;
    this.isThemesReady = true;
    // console.log("this.form.theme", JSON.parse(JSON.stringify(this.form.theme.themes)))
    this.updateForm(this.form);
  }

  clearTheme(){
    this.newThemeMap = null;
    this.isEditOpen = false;
    console.log("this.prevSelectedTheme", this.prevSelectedTheme)
    if(this.prevSelectedTheme){
      this.form.theme.selectedTheme = this.prevSelectedTheme;
      this.formService.themeSubject.next(this.form.theme.selectedTheme);
    }
    this.setPageButton(this.form.theme.selectedTheme)
    this.configureType = "";
    this.formService.preventAutoSave = false;
  }

  setTheme(sTheme, i?){
    this.form = this.formService.formMeta.value;
    console.log("set stheme", sTheme)
    let theme = JSON.parse(JSON.stringify(sTheme));
    if(this.isEditOpen) this.editIndex = i;
    if(this.newThemeMap) this.newThemeMap = theme;
    this.form.theme.selectedTheme = theme;
    console.log("set theme", theme)
    this.prevSelectedTheme = JSON.parse(JSON.stringify(theme));
    this.cdr.detectChanges();
    this.formService.themeSubject.next(theme);
    this.setPageButton(theme)
    this.updateForm(this.form)
  }

  editTheme(sTheme, i){
    if(this.isEditOpen) {
      this.clearTheme();
      return;
    }
    let theme = JSON.parse(JSON.stringify(sTheme));
    this.isEditOpen = true;
    this.formService.preventAutoSave = true;
    console.log("editTheme theme", theme)
    this.newThemeMap = theme;

    if(!this.newThemeMap["button-text-color"]) this.newThemeMap["button-text-color"] = "#f5f5f5";
    if(!this.newThemeMap["button-type"]) this.newThemeMap["button-type"] = "Raised"
    if(!this.newThemeMap["button-color-type"]) this.newThemeMap["button-color-type"] = JSON.parse(JSON.stringify({value: "primary", color: "#f5f5f5"}));
    if(!this.newThemeMap.frameconfig) this.newThemeMap.frameconfig = JSON.parse(JSON.stringify(this.defaultFrameConfig));
    if(!this.newThemeMap.frameconfig.margin) this.newThemeMap.frameconfig.margin = {
      left: 15,
      right: 85,
      top: 2,
      bottom: 98
    }
    this.configureType = "edit";
    this.formService.themeSubject.next(this.newThemeMap);
    this.editIndex = i;
    this.setPageButton(this.newThemeMap)
  }

  // closePanel(index: number): void {
  //   // this.expandedPanels[index] = false;
  //   console.log(`Panel ${index} closed manually`);
  // }

  ngOnDestroy(){
    this.editIndex = null;
    this.newThemeMap = null;
    this.configureType = null;
    this.formMetaSub?.unsubscribe();
    this.formService.themeSubject.next(null)
  }

  colorChanged(e, type?:any){

    let value:any = JSON.parse(JSON.stringify(e.value));
    value = hexRgb(e.value);
    if(type == "frameconfig"){
      if(!this.newThemeMap[type]) this.newThemeMap[type] = JSON.parse(JSON.stringify(this.defaultFrameConfig));
      this.newThemeMap[type]['background-color'] = e.value;
      value = `rgb(${value.red}, ${value.green}, ${value.blue}, ${this.newThemeMap[type].opacity})`;
      this.newThemeMap[type].style['background-color'] = value;
      if(this.newThemeMap[type].image.url) {
        let bgImageValue = this.getBgImageValue(this.newThemeMap[type]['background-color'], this.newThemeMap[type].opacity, this.newThemeMap[type].image.url);
        this.newThemeMap[type]['style']['background-image'] = bgImageValue;
      }
    } else {
      this.newThemeMap['background-color'] = e.value;
      value = `rgb(${value.red}, ${value.green}, ${value.blue}, ${this.newThemeMap.opacity})`;
      this.newThemeMap.style['background-color'] = value;
      if(this.newThemeMap.image.url) {
        let bgImageValue = this.getBgImageValue(this.newThemeMap['background-color'], this.newThemeMap.opacity, this.newThemeMap.image.url);
        this.newThemeMap['style']['background-image'] = bgImageValue;
      }
    }

    console.log("this.this.newThemeMap", this.newThemeMap)
    this.formService.themeSubject.next(this.newThemeMap);
  }

  opacityChanged(type?:any){
    if(type == "frameconfig"){
      let color = this.newThemeMap[type]['background-color'];
      color = hexRgb(color);
      if(!color.red) return;
      if(!this.newThemeMap[type]) this.newThemeMap[type] = JSON.parse(JSON.stringify(this.defaultFrameConfig));
      let colorValue = `rgb(${color.red}, ${color.green}, ${color.blue}, ${this.newThemeMap[type].opacity}%)`;
      this.newThemeMap[type].style['background-color'] = colorValue;
      if(this.newThemeMap[type].image.url) {
        let bgImageValue = this.getBgImageValue(this.newThemeMap[type]['background-color'], this.newThemeMap[type].opacity, this.newThemeMap[type].image.url);
        this.newThemeMap[type]['style']['background-image'] = bgImageValue;
      }
    } else {
      let color = this.newThemeMap['background-color'];
      color = hexRgb(color);
      if(!color.red) return;
      let colorValue = `rgb(${color.red}, ${color.green}, ${color.blue}, ${this.newThemeMap.opacity}%)`;
      this.newThemeMap.style['background-color'] = colorValue;
      if(this.newThemeMap.image.url) {
        let bgImageValue = this.getBgImageValue(this.newThemeMap['background-color'], this.newThemeMap.opacity, this.newThemeMap.image.url);
        this.newThemeMap['style']['background-image'] = bgImageValue;
      }
    }

    this.formService.themeSubject.next(this.newThemeMap);
  }

  customPropertyChanged(e, type?: any){

    if(type == "frameconfig"){
      this.newThemeMap[type].image.url = e.value;
      let bgImageValue = this.getBgImageValue(this.newThemeMap[type]['background-color'], this.newThemeMap[type].opacity, this.newThemeMap[type].image.url);

      if(this.newThemeMap[type].image.url) {
        this.newThemeMap[type]['style']['background-image'] = bgImageValue;
        this.newThemeMap[type]['style']['background-size'] = 'cover';
        this.newThemeMap[type]['style']['background-position'] = "center"
      }
    } else {
      this.newThemeMap.image.url = e.value;
      let bgImageValue = this.getBgImageValue(this.newThemeMap['background-color'], this.newThemeMap.opacity, this.newThemeMap.image.url);
      if(this.newThemeMap.image.url) {
        this.newThemeMap['style']['background-image'] = bgImageValue;
        this.newThemeMap['style']['background-size'] = 'cover';
        this.newThemeMap['style']['background-position'] = "center"
      }
    }

    this.formService.themeSubject.next(this.newThemeMap);
  }

  alignmentChanged(alignment){
    console.log('alignment changed', alignment);
    this.newThemeMap.alignment = alignment.value;
    this.formService.themeSubject.next(this.newThemeMap);
  }

  backgroundImageReset(type?: any){

    if(type == "frameconfig"){
      this.newThemeMap[type].image.url = "";
      this.newThemeMap[type]['style']['background-image'] = "";
    } else {
      this.newThemeMap.image.url = "";
      this.newThemeMap['style']['background-image'] = "";
    }

    this.formService.themeSubject.next(this.newThemeMap);
  }

  backgroundColorReset(type?: any){
    let value = "#f5f5f5";
    if(type == "frameconfig") value = "#ffffff";
    this.colorChanged({value: value}, type)
  }

  async imageSelectionChange(e, type?: any){
    console.log("imageSelectionChange", e)
    if(e){
      this.spinner = true;
      await this.adminService.fileUpload(e).then((res: any) => {
        console.log("image success", res)
        this.spinner = false;
        if(type == "frameconfig"){
          this.newThemeMap[type].image.url = res
          if(this.newThemeMap[type].image.url) {
            let bgImageValue = this.getBgImageValue(this.newThemeMap[type]['background-color'], this.newThemeMap[type].opacity, this.newThemeMap[type].image.url);
            this.newThemeMap[type]['style']['background-image'] = bgImageValue;
            this.newThemeMap[type]['style']['background-size'] = 'cover';
            this.newThemeMap[type]['style']['background-position'] = "center"
          }
        } else {
          this.newThemeMap.image.url = res
          if(this.newThemeMap.image.url) {
            let bgImageValue = this.getBgImageValue(this.newThemeMap['background-color'], this.newThemeMap.opacity, this.newThemeMap.image.url);
            this.newThemeMap['style']['background-image'] = bgImageValue;
            this.newThemeMap['style']['background-size'] = 'cover';
            this.newThemeMap['style']['background-position'] = "center"
          }
        }

        this.formService.themeSubject.next(this.newThemeMap);
      } ).catch(e => {
        console.log("e", e)
        this._snackBar.open('Apologies, image upload failed. Please try uploading again.', 'ok', {
          horizontalPosition: 'right',
          verticalPosition: 'bottom',
        });
      });
    }else{
      this.newThemeMap.image.url = null
    }

    this.spinner = false;
  }

  createNewTheme(){
    this.form = this.formService.formMeta.value;
    this.configureType = "";
    if(!this.form?.theme) this.form.theme = {};
    if(!this.form.theme.themes) this.form.theme.themes = [];
    this.formService.preventAutoSave = true;
    console.log("this.prevSelectedTheme", this.prevSelectedTheme)
    if(this.prevSelectedTheme?.style && this.prevSelectedTheme?.frameconfig) {
      this.newThemeMap = this.prevSelectedTheme;
      this.prevSelectedTheme = JSON.parse(JSON.stringify(this.prevSelectedTheme));
    } else {
      this.newThemeMap = {
        style : {
          "background-color": "#f5f5f5",
          'background-image': ""
        },
        image: {url: ""},
        alignment: "center",
        "background-color": "#f5f5f5",
        opacity: 50,
        "button-type" : "Raised",
        "button-color-type" : {value: "primary", color: "#f5f5f5"},
        "button-text-color" : "#f5f5f5",

      }
      Object.assign(this.newThemeMap, {frameconfig: this.defaultFrameConfig});
      console.log("this.newThemeMap", this.newThemeMap);
      this.prevSelectedTheme = JSON.parse(JSON.stringify(this.newThemeMap));
    }

    this.newThemeMap.code = `theme_form_` + uuidv4(),
    this.newThemeMap.name = "Theme " + (this.form.theme.themes.length + 1)
    // this.form.theme.selectedTheme = this.newThemeMap;
    this.formService.themeSubject.next(this.newThemeMap);
  }


  toggleEditMode() {
    this.isNameEditMode = !this.isNameEditMode;
  }

  deleteTheme(theme, i){
    this.form = this.formService.formMeta.value;
    // event.stopPropagation(); // Prevent the panel from opening
    this.form.theme.themes.splice(i, 1);

    let nextTheme;

    if(this.form.theme.themes[i]) {
      nextTheme = this.form.theme.themes[i];
      this.setTheme(nextTheme, i);
    } else {
      if (i >= 0 && this.form.theme.themes?.length > 0) {
        nextTheme = this.form.theme.themes[i - 1];
        this.setTheme(nextTheme, i - 1);
      } else {
        this.newThemeMap = null;
        this.configureType = "";
        this.form.theme.selectedTheme = null;
        this.cdr.detectChanges();
        this.updateForm(this.form);
        let defaultThemeMap = {
          style : {
            "background-color": "#f5f5f5",
            'background-image': ""
          },
          image: {url: ""},
          alignment: "center",
          "background-color": "#f5f5f5",
          opacity: 50,
          "button-type" : "Raised",
          "button-color-type" : {value: "primary", color: "#f5f5f5"},
          "button-text-color" : "#f5f5f5",

        }
        Object.assign(defaultThemeMap, {frameconfig: this.defaultFrameConfig});
        this.prevSelectedTheme = defaultThemeMap;
        this.formService.themeSubject.next(defaultThemeMap);
      }
    }
  }



  buttonTextColorChanged(newColor: string){
    this.newThemeMap["button-text-color"] = newColor;
    this.setPageButton(this.newThemeMap);
    this.formService.themeSubject.next(this.newThemeMap);
  }

  buttonColorChanged(newColor: string) {
    this.newThemeMap['button-color-type'].color = newColor;
    this.pageService.addToColorPalette(newColor);
    this.setPageButton(this.newThemeMap)
    this.formService.themeSubject.next(this.newThemeMap);
  }

  buttonColorTypeChanged(event: any) {
    this.newThemeMap['button-color-type'].value = event.value;
    this.setPageButton(this.newThemeMap)
    this.formService.themeSubject.next(this.newThemeMap);
  }

  buttonTypeChanged(event: any) {
    this.newThemeMap['button-type'] = event.value;
    this.setPageButton(this.newThemeMap);
    this.formService.themeSubject.next(this.newThemeMap);
  }

  marginChange(event, position: string, margin:any){
    if (position == 'left') {
      margin.left = event;
    } else if (position == 'right') {
      margin.right = event
    } else if (position == 'top') {
      margin.top = event
    } else if (position == 'bottom') {
      margin.bottom = event
    }
  }

  displayThumbText(num, pos){
    // console.log("displayThumbText", num)
    let showVal: any = ''
    if(this.draggingEndForMargin == 'left'){
      showVal = num
    }else if (this.draggingEndForMargin == 'right'){
      showVal = 100 - num
    }else if(this.draggingEndForMargin == 'top'){
      showVal = num
    }else if (this.draggingEndForMargin == 'bottom'){
      showVal = 100 - num
    }
    // console.log("showVal")
    return showVal
  }

  dragStart(event, dir){
    this.draggingEndForMargin = dir;
  }

  formatLabel(value: number): string {
      return value+ '%';
  }

  getBgImageValue(color, opacity, url){
    color = hexRgb(color);
    let colorValue = `rgba(255,255,255, ${opacity}%)`;
    if(color.red) {
      colorValue = `rgba(${color.red}, ${color.green}, ${color.blue}, ${opacity}%)`;
    };

    let value = `linear-gradient(to right, ${colorValue}, ${colorValue}),url(${url})`
    return value;
  }

  updateForm(form){
    console.log("[FORM-UPDATE] theme", JSON.parse(JSON.stringify(form)))
    this.formService.formMeta.next(form || this.form); // Update the form meta with the new widget
    this.formService.userMadeChanges.next(true);
  }

  getDisplayTheme(style){
    console.log(style);
    return style;
  }

  setPageButton(formStyle){
    let form = this.form;
    // console.log("formStyle", formStyle)
    for (let index = 0; index < form.sections?.length; index++) {
      const section = form.sections[index];
      // if(section.code == this.formService?.selectedPage?.code) {
        let actionPanel = section.panels[section.panels.length - 1];

        let prevSectionColId = actionPanel.layoutMap.list[0]
        let prevSectionRowId = actionPanel.layoutMap[prevSectionColId].list[0];
        let buttonsNum = actionPanel.layoutMap[prevSectionColId][prevSectionRowId]['elements'].length;
        let nextButton = actionPanel.layoutMap[prevSectionColId][prevSectionRowId]['elements'][buttonsNum - 1];
        if(buttonsNum == 2) {
          var preButton = actionPanel.layoutMap[prevSectionColId][prevSectionRowId]['elements'][0];
        }

        nextButton.config.buttonType.value = formStyle?.['button-type'] || "Raised";

        nextButton.config.buttonColorType.value = formStyle?.['button-color-type']?.value || 'primary';
        nextButton.config.buttonColorType.type = "buttonColor";
        if(nextButton?.textFormat?.color) nextButton.textFormat.color.value = formStyle?.['button-text-color'] || "#f5f5f5";

        if(nextButton.config.buttonColorType.value == "custom") {
          nextButton.config.buttonColorType.customValue = formStyle?.['button-color-type']?.color || "#f5f5f5"
          if(preButton && formStyle?.['button-color-type']?.color) {
            if(preButton?.textFormat?.color) preButton.textFormat.color.value = formStyle?.['button-color-type']?.color;
          }
        } else nextButton.config.buttonColorType.customValue = "";

        if(preButton && formStyle?.['button-color-type']?.value != 'custom'){
          preButton.config.buttonColorType.value = formStyle?.['button-color-type']?.value || 'primary';
          preButton.config.buttonColorType.type = "buttonColor";
        }

        if(section.code == this.formService?.selectedPage?.code){
          this.metaService.contextChanged.next({  //this is to make the change in widgetMeta reach down to widget component level
            actionType: "updateStyles",
            data: nextButton,
            widgetId: nextButton.id
          })
        }

        // console.log("preButton", preButton);
        console.log("nextButton", nextButton)
        // break;
      // }
    }
  }

  trackById(index: number, item: any): any {
    return item.code || index;
  }

  getCombinedFramedStyles(theme){
    theme?.frameconfig?.style ? theme?.frameconfig?.style : {'background-color': '#fff'}
    let style = theme?.frameconfig?.style;
    if(!style) {
      style = {'background-color': '#fff'}
    }

    let result = {
      ...style,
      ...this.getMargin(theme?.frameconfig?.margin)
    }
    return result;
  }

  getMargin(margin){
    let marginCss = {};
    let marginLeft = margin?.left || 15;
    let marginRight = margin?.right || 85;
    let marginTop = margin?.top || 2;
    let marginBottom = margin?.bottom || 98;
    marginCss = {
      "margin-left": (marginLeft) + "%",
      "margin-right": (100 - marginRight) + "%",
      "margin-top": (marginTop) + "%",
      "margin-bottom": (100 - marginBottom) + "%",
    }
    return marginCss;
  }


}

