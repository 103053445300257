import { SelectionModel } from '@angular/cdk/collections';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ClientPlatformService } from 'src/app/client-platform/client-platform.service';
import { SpinnerService } from 'src/app/shared/spinner/spinner.service';

@Component({
  selector: 'app-create-object.dialog',
  templateUrl: './create-object.dialog.html',
  styleUrls: ['./create-object.dialog.scss'],
})
export class CreateObjectDialogComponent implements OnInit {
  displayedColumns: string[] = ['select', 'type', 'zoho_attr_name', 'app_attr_name'];
  dataSource: any;
  selection = new SelectionModel<any>(true, []);
  isError: boolean = false;
  canContinueEnabled: boolean = false;
  statusMessage: string;
  objResponse: any;
  objectName: string;
  displayNameObj: any;
  
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public spinnerService: SpinnerService,
    public clientPlatformService: ClientPlatformService
  ) {}

  ngOnInit(): void {
    this.objectName = this.data.sourceObjName?.toLowerCase();
    this.displayNameObj = this.data.displayNameObj || {};
    /*format attributes*/
    this.formatAttributes();
    this.selection.select(...this.dataSource.data);
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

   /** Selects all rows if they are not all selected; otherwise clear selection. */
  toggleAllRows() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }

    this.selection.select(...this.dataSource.data);
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: any): string {
    if (!row) { 
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
  }

  onChangeOfObjectName(value: string) {
    this.objectName = value.toLowerCase().replaceAll(' ', '_');
  }

  formatAttributes() {
    const formattedAttr = this.data.attributes.map((attr) => ({ 
        type: attr.dataType,
        zoho_attr_name: attr.name,
        app_attr_name: attr.name.replaceAll(/[\/ ]/g, '_'),
        primary: attr.primary
    }))
    this.dataSource = new MatTableDataSource<any>(formattedAttr);
  }

  async createObject() {
    this.spinnerService.show();
    this.isError = false;
    this.statusMessage = '';
    this.canContinueEnabled = false;
    this.objectName = this.objectName.toLowerCase().replaceAll(' ', '_');

    const attributes = this.selection.selected.map((obj) => ({name: obj.app_attr_name, dataType: obj.type, primary: obj.primary }))
    const payload = { parameters: { object: this.objectName, attributes } };
    this.objResponse = await this.clientPlatformService.executeBoxFn(this.data.connection, payload, 'createobject');
    console.log('objResponse', this.objResponse)
    if (this.objResponse?.error) {
      this.isError = true;
      this.statusMessage = this.objResponse.error;
    } else {
      this.canContinueEnabled = true;
      this.statusMessage = `${
        this.displayNameObj?.app?.objDisplayName
          ? this.titleCase(this.displayNameObj.app.objDisplayName)
          : 'Object'
      } created successfully!`;
    }
    this.spinnerService.hide();
  }

  titleCase(str: string) {
    return str.toLowerCase().replace(/\b\w/g, (s) => s.toUpperCase());
  }
}
